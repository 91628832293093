
import {
  /* CONST */
  PLAN_CODE,
  /* TYPE */
  TypeOption,
  TypePlanCode,
} from '../modules/interface';
import { IOrderItem } from '../modules/OrderItem';

export default {
  name: 'ComplexOptions',
  props: {
    itemInfo: {
      type: Object as () => IOrderItem,
      required: true,
    },
  },
  data() {
    return {
      optionList: [] as Array<TypeOption>,
      selected: 0 as number,
    };
  },
  watch: {
    selected() {
      const selectedOption = this.optionList.find((opt) => opt.idx === this.selected);
      // eslint-disable-next-line vue/no-mutating-props
      this.itemInfo.options = [selectedOption];
    },
  },
  created() {
    const { code, options } = this.itemInfo;
    const showComplexOptions: Array<TypePlanCode> = [PLAN_CODE.EVENT_2021FOCUSPACK];
    if (showComplexOptions.includes(code)) {
      this.optionList = [...options];
      this.selected = options[0].idx;
    }
  },
};
