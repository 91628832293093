<template>
  <div class="card-info" v-if="card.cardName && card.cardNumber">
    <div class="header">
      <p class="title">카드번호</p>
      <button class="register-btn" @click="confirmRegisterCard">카드 신규등록</button>
    </div>
    <div class="contents">
      <img class="ico" src="//res.tutoring.co.kr/res/images/img/plans/ico_credit-card.png" alt="" />
      <span class="name">{{ card.cardName }}</span>
      <span class="number">
        <span>****</span>
        <span>****</span>
        <span>****</span>
        <span>{{ card.cardNumber.substring(12, 16) }}</span>
      </span>
    </div>
  </div>
  <ul class="register-card" v-else>
    <li class="item">
      <p class="title">카드번호</p>
      <div class="inputs">
        <input
          v-for="i in newCard.cardNumber.length"
          :key="i"
          class="card-number"
          type="number"
          maxLength="4"
          v-model="newCard.cardNumber[i - 1]"
          :data-idx="i"
          :ref="`registerCard${i}`"
          @input="checkInput"
          @blur="registerCard"
        />
      </div>
    </li>
    <li class="item">
      <p class="title">유효기간</p>
      <div class="inputs">
        <input
          class="date-number"
          type="number"
          maxLength="2"
          placeholder="MM"
          id="expirationMonth"
          v-model="newCard.expirationMonth"
          data-idx="5"
          :ref="'registerCard5'"
          @input="checkInput"
          @blur="goRegisterCard"
        />
        <label class="label" for="expirationMonth" style="padding-right: 35px">월</label>
        <input
          class="date-number"
          type="number"
          maxLength="2"
          placeholder="YY"
          id="expirationYear"
          v-model="newCard.expirationYear"
          data-idx="6"
          :ref="'registerCard6'"
          @input="checkInput"
          @blur="goRegisterCard"
        />
        <label class="label" for="expirationYear">년</label>
      </div>
    </li>
    <li class="item">
      <p class="title">카드 비밀번호 앞2자리</p>
      <input
        class="pwd-number"
        type="number"
        maxLength="2"
        placeholder="앞 2자리 입력"
        id="pwd2Digit"
        v-model="newCard.pwd2Digit"
        data-idx="7"
        :ref="'registerCard7'"
        @input="checkInput"
        @blur="goRegisterCard"
      />
      <label class="dots" for="pwd2Digit" />
    </li>
    <li class="item">
      <p class="title">생년월일 6자리(사업자번호 10자리)</p>
      <input
        class="birth-number"
        type="number"
        maxLength="10"
        placeholder="'-' 생략하고 입력"
        id="birth"
        v-model="newCard.birth"
        data-idx="8"
        :ref="'registerCard8'"
        @input="checkInput"
        @blur="goRegisterCard"
      />
    </li>
  </ul>
  <div class="policy">
    <p class="title">구매 조건 확인 및 결제대행 서비스 약관</p>
    <ul class="list">
      <li class="item">
        <span class="title">정기과금약관</span>
        <button class="btn" @click="openPolicy(100)">보기</button>
      </li>
      <li class="item">
        <span class="title">전자금융거래 이용약관</span>
        <button class="btn" @click="openPolicy(101)">보기</button>
      </li>
      <li class="item">
        <span class="title">개인정보 수집 및 이용약관</span>
        <button class="btn" @click="openPolicy(102)">보기</button>
      </li>
      <li class="item">
        <span class="title">개인정보 3자 제공 및 위탁약관</span>
        <button class="btn" @click="openPolicy(103)">보기</button>
      </li>
    </ul>
  </div>
  <Alert class="alert" :title="alertTitle" :msg="alertMsg" :btns="alertBtns" @close="closeAlert" />
</template>

<script lang="ts">
import { IOrderPay } from '../modules/OrderPay';
import { Alert } from '../components';

export default {
  name: 'PaySubscribe',
  components: {
    Alert,
  },
  props: {
    payInfo: {
      type: Object as () => IOrderPay,
      required: true,
    },
  },
  data() {
    return {
      card: {
        carName: '',
        carNumber: '',
      },
      newCard: {
        cardNumber: ['', '', '', ''],
        expirationMonth: '',
        expirationYear: '',
        pwd2Digit: '',
        birth: '',
      },
      alertTitle: '',
      alertMsg: '',
      alertBtns: null,
    };
  },
  methods: {
    confirmRegisterCard() {
      this.alertTitle = '카드 정보를 변경하시겠어요?';
      this.alertMsg =
        '카드 신규 등록 시, 기존에 결제되던 정기 결제 정보도 해당 카드로 업데이트됩니다.\n\n새롭게 등록하시겠습니까?';
      this.alertBtns = [
        { label: '아니요', handler: () => this.closeAlert() },
        {
          label: '신규등록',
          handler: () => {
            this.card.cardName = '';
            this.card.cardNumber = '';
            this.closeAlert();
            this.$nextTick(() => this.$refs.registerCard1.focus());
          },
        },
      ];
    },
    registerCard() {
      // eslint-disable-next-line vue/no-mutating-props
      this.payInfo.card = this.newCard;
    },
    checkInput(e) {
      const {
        value,
        maxLength,
        id,
        dataset: { idx },
      } = e.target;
      if (value.length >= maxLength) {
        const inputs = Object.keys(this.$refs).filter((ref) => ref.includes('registerCard'));
        const newVal = value.slice(0, maxLength);
        const idxInt = Number(idx);
        if (idxInt < 5) {
          this.cardNumber[idxInt - 1] = newVal;
        } else {
          this[id] = newVal;
        }
        if (idxInt < inputs.length) {
          this.$refs[`registerCard${idxInt + 1}`].focus();
        } else {
          this.$refs[`registerCard${idx}`].blur();
        }
        this.registerCard();
      }
    },
    async openPolicy(type: number) {
      const { title, content } = await this.payInfo.getSubscribePolicy(type);
      this.alertTitle = title;
      this.alertMsg = content;
      this.alertBtns = [
        {
          label: '닫기',
          handler: () => this.closeAlert(),
        },
      ];
    },
    closeAlert() {
      this.alertTitle = '';
      this.alertMsg = '';
      this.alertBtns = null;
    },
  },
  async mounted() {
    const card = await this.payInfo.getSubscribeCardInfo();
    this.card = card;
  },
};
</script>

<style lang="scss" scoped>
.card-info {
  position: relative;
  margin: 14px 20px 0;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  .register-btn {
    padding: 8px 12px;
    color: #34bcff;
    border: 1px solid #34bcff;
    border-radius: 17px;
    font-weight: 500;
  }
  .contents {
    padding: 24px 22px 26px;
    background: #f8f8f8;
    border-radius: 10px;
  }
  .ico {
    width: 24px;
    height: 18px;
    vertical-align: middle;
    margin-right: 10px;
    display: inline-block;
  }
  .name {
    color: #555555;
    font-weight: 700;
    line-height: 2.6;
    margin-right: 10px;
  }
  .number {
    font-size: 1.145rem;
    font-weight: 400;
    color: #919191;
    span {
      padding: 0 3px;
    }
  }
}

.register-card {
  margin: 14px 20px 0;
  .item {
    margin-bottom: 12px;
    input {
      height: 46px;
      margin: 0;
      padding: 0 10px;
      border-radius: 8px;
      border: 2px solid #f2f2f2;
      font-size: 1.1rem;
      color: #919191;
      box-shadow: none;
      appearance: none;
      text-align: center;
      &:focus {
        border-color: #34bcff;
      }
    }
  }
  .title {
    margin-bottom: 8px;
  }
  .card-number {
    width: 21.32%;
    max-width: 65.56px;
    & + .card-number {
      margin-left: 7px;
    }
  }
  .date-number {
    width: 27.7%;
    min-width: 100px;
    & + .label {
      padding-left: 10px;
    }
  }
  .pwd-number {
    width: 27.7%;
    min-width: 100px;
    -webkit-text-security: disc;
    & + .dots {
      &::before {
        content: '';
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background-color: #4a4a4a;
        margin: 0 17px 0 20px;
      }
      &::after {
        content: '';
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background-color: #4a4a4a;
      }
    }
  }
  .birth-number {
    width: 78.6%;
    min-width: 283px;
  }
}

.policy {
  margin: 44px 20px 0;
  padding: 0 10px 16px;
  border-top: 1px solid #cbced1;
  .title {
    padding: 18px 0 21px;
  }
  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
      padding: 0;
      line-height: 1.55;
    }
    .btn {
      color: #2eb4ff;
    }
  }
}
:deep(.alert) {
}
.alert::v-deep {
  .title {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 22px;
    padding-bottom: 5px;
    background: #ffffff;
    border-radius: 11px 11px 0 0;
    text-align: left;
    text-indent: 24px;
  }
  .message {
    padding-top: 2.1rem;
    text-align: left;
    color: #777777;
  }
  .btn + .btn {
    font-weight: bold;
  }
}

.title {
  font-size: 1.075rem;
  font-weight: 400;
  line-height: 1.75;
}

input {
  outline: none;
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
