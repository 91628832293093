import { render } from "./StandardPlan.vue?vue&type=template&id=ebca8cb0&scoped=true"
import script from "./StandardPlan.vue?vue&type=script&lang=ts"
export * from "./StandardPlan.vue?vue&type=script&lang=ts"

import "./StandardPlan.vue?vue&type=style&index=0&id=ebca8cb0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-ebca8cb0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "ebca8cb0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ebca8cb0', script)) {
    api.reload('ebca8cb0', script)
  }
  
  module.hot.accept("./StandardPlan.vue?vue&type=template&id=ebca8cb0&scoped=true", () => {
    api.rerender('ebca8cb0', render)
  })

}

script.__file = "src/page/order/view/StandardPlan.vue"

export default script