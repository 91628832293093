import { render } from "./DeliveryForm.vue?vue&type=template&id=5a51eb91&scoped=true"
import script from "./DeliveryForm.vue?vue&type=script&lang=ts"
export * from "./DeliveryForm.vue?vue&type=script&lang=ts"

import "./DeliveryForm.vue?vue&type=style&index=0&id=5a51eb91&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5a51eb91"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5a51eb91"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5a51eb91', script)) {
    api.reload('5a51eb91', script)
  }
  
  module.hot.accept("./DeliveryForm.vue?vue&type=template&id=5a51eb91&scoped=true", () => {
    api.rerender('5a51eb91', render)
  })

}

script.__file = "src/page/order/sections/DeliveryForm.vue"

export default script