
import { Alert } from '../components';

export default {
  name: 'Footer',
  components: {
    Alert,
  },
  props: {
    guideList: {
      type: Object as () => Array<{
        title: string;
        contents: Array<{
          text: string;
        }>;
      }>,
      required: true,
      default() {
        return [] as Array<{
          title: string;
          contents: Array<{
            text: string;
          }>;
        }>;
      },
    },
  },
  data() {
    return {
      alertMsg: '',
    };
  },
  methods: {
    contactCsCenter() {
      if (this.checkIsCsCenterAvailable()) {
        window.open('tel:15227802');
      } else {
        this.alertMsg = '문의 전화는 평일 10:00 ~ 18:00 시 까지 가능합니다.';
      }
    },
    checkIsCsCenterAvailable() {
      const now = new Date();
      const isWeekend = now.getDay() === 0 || now.getDay() === 6;
      const isWorkingTime = now.getHours() >= 10 && now.getHours() <= 17;
      if (isWeekend) return false;
      if (!isWorkingTime) return false;
      return true;
    },
  },
};
