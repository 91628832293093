import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e1306c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post-search" }
const _hoisted_2 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDaumPostcode = _resolveComponent("VueDaumPostcode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "close",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueDaumPostcode, {
        class: "content",
        onComplete: $options.onComplete
      }, null, 8 /* PROPS */, ["onComplete"])
    ])
  ]))
}