import { render } from "./ComplexOptions.vue?vue&type=template&id=4559e572&scoped=true"
import script from "./ComplexOptions.vue?vue&type=script&lang=ts"
export * from "./ComplexOptions.vue?vue&type=script&lang=ts"

import "./ComplexOptions.vue?vue&type=style&index=0&id=4559e572&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4559e572"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4559e572"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4559e572', script)) {
    api.reload('4559e572', script)
  }
  
  module.hot.accept("./ComplexOptions.vue?vue&type=template&id=4559e572&scoped=true", () => {
    api.rerender('4559e572', render)
  })

}

script.__file = "src/page/order/sections/ComplexOptions.vue"

export default script