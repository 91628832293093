import { render } from "./ComplexPlan.vue?vue&type=template&id=52a65e47&scoped=true"
import script from "./ComplexPlan.vue?vue&type=script&lang=ts"
export * from "./ComplexPlan.vue?vue&type=script&lang=ts"

import "./ComplexPlan.vue?vue&type=style&index=0&id=52a65e47&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-52a65e47"
/* hot reload */
if (module.hot) {
  script.__hmrId = "52a65e47"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('52a65e47', script)) {
    api.reload('52a65e47', script)
  }
  
  module.hot.accept("./ComplexPlan.vue?vue&type=template&id=52a65e47&scoped=true", () => {
    api.rerender('52a65e47', render)
  })

}

script.__file = "src/page/order/view/ComplexPlan.vue"

export default script