<template>
  <footer class="footer">
    <!-- 수강권 유의사항 -->
    <section class="guide">
      <div class="block" v-for="(block, i) in guideList" :key="i">
        <strong class="title">{{ block.title }}</strong>
        <ul class="list">
          <li class="item" v-for="(content, j) in block.contents" :key="j">
            {{ content.text }}
          </li>
        </ul>
      </div>
    </section>
    <!-- 튜달이 콜센터 -->
    <section class="cs-center">
      <strong class="name">튜달이 콜센터</strong>
      <p class="contact" @click="contactCsCenter">1522-7802</p>
      <p class="time">(평일 오전 10시 ~ 오후 6시)</p>
    </section>
  </footer>
  <Alert :msg="alertMsg" @close="alertMsg = ''" />
</template>

<script lang="ts">
import { Alert } from '../components';

export default {
  name: 'Footer',
  components: {
    Alert,
  },
  props: {
    guideList: {
      type: Object as () => Array<{
        title: string;
        contents: Array<{
          text: string;
        }>;
      }>,
      required: true,
      default() {
        return [] as Array<{
          title: string;
          contents: Array<{
            text: string;
          }>;
        }>;
      },
    },
  },
  data() {
    return {
      alertMsg: '',
    };
  },
  methods: {
    contactCsCenter() {
      if (this.checkIsCsCenterAvailable()) {
        window.open('tel:15227802');
      } else {
        this.alertMsg = '문의 전화는 평일 10:00 ~ 18:00 시 까지 가능합니다.';
      }
    },
    checkIsCsCenterAvailable() {
      const now = new Date();
      const isWeekend = now.getDay() === 0 || now.getDay() === 6;
      const isWorkingTime = now.getHours() >= 10 && now.getHours() <= 17;
      if (isWeekend) return false;
      if (!isWorkingTime) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 40px;
  padding: 21px 26px 59px;
  background-color: #efefef;
}

.guide {
  .title {
    display: block;
    color: #777;
    font-weight: 700;
    font-size: 1.07rem;
  }
  .block {
    margin-top: 20px;
  }
  .item {
    position: relative;
    padding-left: 13px;
    margin-top: 12px;
    font-size: 0.858rem;
    color: #8b8b8b;
    word-break: break-word;
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: #777;
    }
  }
}

.cs-center {
  padding-top: 56px;
  text-align: center;
  color: #8b8b8b;
  .name {
    display: block;
    height: 0.93rem;
    font-size: 0.93rem;
    font-weight: normal;
    line-height: 0.93rem;
  }
  .contact {
    color: #8b8b8b;
    letter-spacing: 0;
    font-weight: 700;
    font-size: 1.571rem;
    cursor: pointer;
  }
  .time {
    height: 0.86rem;
    font-size: 0.86rem;
    line-height: 0.86rem;
  }
}
</style>
