import { storage, env } from '@/tools';
import api from '@/common/api';
import {
  /* CONST */
  ORDER_TYPE,
  DELIVERY_TYPE,
  PLAN_CODE,
  PAY_CHANNEL,
  PAY_METHOD,
  BENEFIT_TYPE,
  PRODUCT_TYPE,
  /* TYPE */
  TypePlan,
  TypeGuide,
  TypePlanCode,
  TypeOrderType,
  TypePayMethod,
  TypeBenefitType,
  TypePayment,
} from './interface';
import OrderItem, { IOrderItem } from './OrderItem';
import OrderUser, { IOrderUser } from './OrderUser';
import OrderDelivery, { IOrderDelivery } from './OrderDelivery';
import OrderPay, { IOrderPay } from './OrderPay';
import OrderBenefit, { IOrderBenefit } from './OrderBenefit';

export interface IOrder {
  type: TypeOrderType;
  item: IOrderItem;
  user: IOrderUser;
  delivery: IOrderDelivery;
  pay: IOrderPay;
  benefit: IOrderBenefit;
  submitPay(
    finalPrice: number,
    benefit: {
      type: TypeBenefitType;
      amount: number;
      dcIdx?: number;
    },
  ): Promise<{
    success: boolean;
    data: unknown;
  }>;
  submitSubscribe(): Promise<{
    success: boolean;
    data: unknown;
  }>;
  submitDelivery(mtIdx: string): Promise<{
    success: boolean;
    data: unknown;
  }>;
  saveSessionPayment(mtIdx: string, finalPrice: number): void;
  saveSessionDelivery(): void;
  getPlanGuide(): Promise<Array<TypeGuide>>;
  getPlanOptions(): Promise<Array<TypePlan>>;
}

export default class Order implements IOrder {
  private _item: IOrderItem;

  private _user: IOrderUser;

  private _delivery: IOrderDelivery;

  private _pay: IOrderPay;

  private _benefit: IOrderBenefit;

  get type(): TypeOrderType {
    if (this._pay.isSubscribe) return ORDER_TYPE.SUBSCRIBE;
    if (this._delivery.type !== DELIVERY_TYPE.NONE) return ORDER_TYPE.COMPLEX;
    return ORDER_TYPE.STANDARD;
  }

  get item(): IOrderItem {
    return this._item;
  }

  get user(): IOrderUser {
    return this._user;
  }

  get delivery(): IOrderDelivery {
    return this._delivery;
  }

  get pay(): IOrderPay {
    return this._pay;
  }

  get benefit(): IOrderBenefit {
    return this._benefit;
  }

  constructor(planInfo: TypePlan) {
    const userInfo = storage.session.get('loggedInUser');

    this._item = new OrderItem(planInfo, userInfo);
    this._user = new OrderUser(userInfo);
    this._delivery = new OrderDelivery(planInfo);
    this._pay = new OrderPay(planInfo);
    this._benefit = new OrderBenefit(planInfo);
  }

  async submitPay(
    finalPrice: number,
    benefit: {
      type: TypeBenefitType;
      amount: number;
      dcIdx?: number;
    },
  ): Promise<{
    success: boolean;
    data: unknown;
  }> {
    try {
      const needCoursePlans: Array<TypePlanCode> = [PLAN_CODE.ALPHA_PLAN, PLAN_CODE.TUTORING_PLAY];
      const payMethodCodes: {
        [key in Exclude<TypePayMethod, 'SP'>]: number;
      } = {
        [PAY_METHOD.CARD]: 11,
        [PAY_METHOD.VIRTUAL_ACCOUNT]: 31,
        [PAY_METHOD.PHONE]: 41,
        [PAY_METHOD.PAYMENTWALL]: 50,
      };

      const { code, isGroup, cpIdx, cpgIdx, courseIdxs } = this._item;
      const { isDollar, method } = this._pay;
      const { type: benefitType, dcIdx, amount: usePoint } = benefit;
      const isComplexOrder = this.type === ORDER_TYPE.COMPLEX;
      const isNeedCourse = needCoursePlans.includes(code);
      const isBenefitCoupon = benefitType === BENEFIT_TYPE.COUPON;
      const isBenefitPoint = benefitType === BENEFIT_TYPE.POINT;
      const payMethod = payMethodCodes[method];

      const params: {
        money: number;
        is_dollar: number;
        pay_method: number;
        pay_channel: 0 | 1;
        cp_idx?: number;
        cpg_idx?: number;
        dc_idx?: number;
        use_point?: number;
        course_idxs?: string;
      } = {
        money: finalPrice,
        is_dollar: isDollar,
        pay_method: payMethod,
        pay_channel: env.isInApp() ? 0 : 1,
      };
      if (!isGroup) params.cp_idx = cpIdx;
      if (isGroup) params.cpg_idx = cpgIdx;
      if (isNeedCourse) params.course_idxs = courseIdxs;
      if (isBenefitCoupon && dcIdx) params.dc_idx = dcIdx;
      if (isBenefitPoint && usePoint) params.use_point = usePoint;

      const {
        data: { mt_idx },
      } = isComplexOrder
        ? await api.post('/mvc/make_charge_complex_order', params)
        : await api.post('/mvc/make_charge_plan_order', params);
      if (!mt_idx) throw new Error('Server error! Contact CS center');
      return { success: true, data: mt_idx };
    } catch (error) {
      console.error(error);
      return { success: false, data: (error as Error).message };
    }
  }

  async submitSubscribe(): Promise<{
    success: boolean;
    data: unknown;
  }> {
    const isNewCard = !!this._pay.card.cardNumber.join('');
    if (isNewCard) {
      const result = await this._pay.setSubscribeCardInfo();
      if (!result.success) return result;
    }
    const { cpIdx } = this._item;
    const result = this._pay.subscribe(cpIdx);
    return result;
  }

  async submitDelivery(mtIdx: string): Promise<{
    success: boolean;
    data: unknown;
  }> {
    try {
      const { code, options } = this._item;
      const {
        recipient: name,
        mobile: phone,
        tel,
        zipCode: zip_code,
        address1,
        address2,
        message: memo,
      } = this._delivery;
      const addr = `${address1} ${address2}`;

      const [optionTitle, optionCode] = options.reduce(
        (strArr, opt) => {
          strArr[0].push(opt.title);
          strArr[1].push(opt.code);
          return strArr;
        },
        [[], []] as [Array<string>, Array<string>],
      );
      const productType =
        {
          [PLAN_CODE.EVENT_2021FOCUSPACK]: PRODUCT_TYPE.DEVICE,
          [PLAN_CODE.JICDING_ENGLISHTECH]: PRODUCT_TYPE.JICDING,
          [PLAN_CODE.DAWATDA_PASS]: PRODUCT_TYPE.DAWATDA,
          [PLAN_CODE.MINI_BOOK_PLAN]: PRODUCT_TYPE.MINI_BOOK,
        }[code] || PRODUCT_TYPE.PACKAGE;
      const params = {
        mt_idx: mtIdx,
        name,
        tel,
        phone,
        addr,
        memo,
        zip_code,
        d_name: optionTitle.toString(),
        d_code: optionCode.toString(),
        p_type: productType,
      };
      const { msg, soIdx } = await api.post('/mvc/make_product_order_info', params);
      if (msg !== 'OK') throw new Error('Server error! Contact CS center');
      return { success: true, data: soIdx };
    } catch (error) {
      console.error(error);
      return { success: false, data: (error as Error).message || '' };
    }
  }

  saveSessionPayment(mtIdx: string, finalPrice: number): void {
    const {
      code,
      nameForPG: titleForPG,
      period: { months },
    } = this._item;
    const { method: payMethod } = this._pay;
    const goodsForPayment = {
      mtIdx,
      code,
      titleForPG,
      payMethod,
      totalPaidMoney: finalPrice,
      months,
    } as TypePayment;
    storage.session.set('goodsForPayment', goodsForPayment);
  }

  saveSessionDelivery(): void {
    const { recipient, mobile, tel, address1, address2, zipCode, message } = this._delivery;
    const addr = `${address1} ${address2}`;
    const shopOrder = {
      recipient,
      tel,
      mobile,
      addr,
      zipCode,
      message,
    };
    storage.session.set('goodsForPayment', shopOrder);
  }

  async getPlanGuide(): Promise<Array<TypeGuide>> {
    try {
      const { country } = this._user;
      const { cpcIdx: cpcIdxs } = this._item;
      const payChannel = env.isInApp() ? PAY_CHANNEL.INAPP : PAY_CHANNEL.WEB;
      const { data: guideList } = await api.get('/app/chargePlan/guideByCategory', {
        cpcIdxs,
        country,
        payChannel,
      });
      return guideList;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getPlanOptions(): Promise<Array<TypePlan>> {
    const { code } = this._item;
    const appMode = storage.persistent.get('appMode');
    const payChannel = env.isInApp() ? PAY_CHANNEL.INAPP : PAY_CHANNEL.WEB;
    const hasPlanOptions: Array<TypePlanCode> = [PLAN_CODE.ALPHA_PLAN, PLAN_CODE.JICDING_ENGLISHTECH];
    if (!hasPlanOptions.includes(code)) return [];
    try {
      const {
        data: { plans },
      } = await api.get('/app/chargePlan/planInfo', {
        dispMode: appMode,
        goods_type: code,
        pay_channel: payChannel,
      });
      return plans;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
