import api from '@/common/api';
import {
  /* CONST */
  BENEFIT_OPTIONS,
  /* TYPE */
  TypePlan,
  TypeBenefitOptions,
  TypeCoupon,
} from './interface';

export interface IOrderBenefit {
  idxs: {
    cpIdx: number;
    cpgIdx: number;
  };
  option: TypeBenefitOptions;
  maxAmount: number;
  maxAmountRepurchase: number;
  maxPercent: string;
  maxPercentRepurchase: string;
  getIsRepurchaseBenefitTarget(): Promise<boolean>;
  getAvailablePoints(): Promise<number>;
  getAvailableCoupons(): Promise<Array<TypeCoupon>>;
}

export default class OrderBenefit implements IOrderBenefit {
  private _idxs: {
    cpIdx: number;
    cpgIdx: number;
  };

  private _option: TypeBenefitOptions;

  private _maxAmount: number;

  private _maxAmountRepurchase: number;

  private _maxPercent: string;

  private _maxPercentRepurchase: string;

  get idxs(): {
    cpIdx: number;
    cpgIdx: number;
  } {
    return this._idxs;
  }

  get option(): TypeBenefitOptions {
    return this._option;
  }

  get maxAmount(): number {
    return this._maxAmount;
  }

  get maxAmountRepurchase(): number {
    return this._maxAmountRepurchase;
  }

  get maxPercent(): string {
    return this._maxPercent;
  }

  get maxPercentRepurchase(): string {
    return this._maxPercentRepurchase;
  }

  constructor(planInfo: TypePlan) {
    const {
      additionalInfo: { additionalBenefitType: option },
      cp_idx: cpIdx,
      cpg_idx: cpgIdx,
      pointInfo: {
        useMaxPoint: maxAmount,
        useRepurchaseMaxPoint: maxAmountRepurchase,
        usePointPercentage: maxPercent,
        repurchaseUsePointPercentage: maxPercentRepurchase,
      },
    } = planInfo;

    this._idxs = { cpIdx, cpgIdx };
    this._option = option;
    this._maxAmount = maxAmount;
    this._maxAmountRepurchase = maxAmountRepurchase;
    this._maxPercent = maxPercent;
    this._maxPercentRepurchase = maxPercentRepurchase;
  }

  async getIsRepurchaseBenefitTarget(): Promise<boolean> {
    const option = this._option;
    const maxPercentRepurchase = this._maxPercentRepurchase;
    let isRepurhchaseBenefitTarget = false;
    if (option === BENEFIT_OPTIONS.BOTH || option === BENEFIT_OPTIONS.POINT) {
      try {
        const {
          data: { remainPlanExceptPromotion },
        } = await api.post('/app/chargePlan/getStatusForRepurchaseUser');
        const maxPercent = Number(maxPercentRepurchase);
        isRepurhchaseBenefitTarget = remainPlanExceptPromotion && maxPercent > 0;
      } catch (error) {
        console.error(error);
      }
    }
    return isRepurhchaseBenefitTarget;
  }

  async getAvailablePoints(): Promise<number> {
    try {
      const { current_point: currentPoint } = await api.post('/mvc/get_user_available_points_at_today');
      return Number(currentPoint);
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  async getAvailableCoupons(): Promise<Array<TypeCoupon>> {
    try {
      const { cpIdx, cpgIdx } = this._idxs;
      const {
        data: { coupons },
      } = await api.post('/app/dccoupon/myAvailableCoupon', {
        cp_idx: cpIdx || 0,
        cpg_idx: cpgIdx || 0,
      });
      return coupons;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
