<template>
  <div class="order">
    <StandardPlan
      v-if="isStandardPlan"
      :orderInfo="orderInfo"
      :planOptions="planOptions"
      @changePlan="changePlan"
      @showAlert="showAlert"
      @setIsLoading="setIsLoading"
    />
    <ComplexPlan
      v-if="isComplexPlan"
      :orderInfo="orderInfo"
      :planOptions="planOptions"
      @changePlan="changePlan"
      @showAlert="showAlert"
      @setIsLoading="setIsLoading"
    />
    <SubscribePlan v-if="isSubscribePlan" :orderInfo="orderInfo" @showAlert="showAlert" @setIsLoading="setIsLoading" />
    <!-- 수강권 안내 -->
    <Footer :guideList="planGuide" />
    <!-- Alert 팝업 -->
    <Alert :title="alertTitle" :msg="alertMsg" @close="closeAlert" />
    <!-- 스피너 -->
    <Loading :isLoading="isLoading" />
  </div>
</template>

<script lang="ts">
import { storage } from '@/tools';
import {
  /* CONST */
  ORDER_TYPE,
  /* TYPE */
  TypePlan,
  TypeGuide,
} from './modules/interface';
import Order, { IOrder } from './modules/Order';
import { StandardPlan, ComplexPlan, SubscribePlan } from './view';
import { Footer } from './sections';
import { Alert, Loading } from './components';

export default {
  name: 'Order',
  components: {
    /* VIEW */
    StandardPlan,
    ComplexPlan,
    SubscribePlan,
    /* SECTION */
    Footer,
    /* COMPONENT */
    Alert,
    Loading,
  },
  data() {
    return {
      orderInfo: {} as IOrder,
      planGuide: [] as Array<TypeGuide>,
      planOptions: [] as Array<TypePlan>,
      alertTitle: '' as string,
      alertMsg: '' as string,
      isLoading: false as boolean,
    };
  },
  computed: {
    isStandardPlan() {
      return this.orderInfo.type === ORDER_TYPE.STANDARD;
    },
    isComplexPlan() {
      return this.orderInfo.type === ORDER_TYPE.COMPLEX;
    },
    isSubscribePlan() {
      return this.orderInfo.type === ORDER_TYPE.SUBSCRIBE;
    },
  },
  methods: {
    changePlan(plan: TypePlan) {
      const orderInfo = new Order(plan);
      this.orderInfo = orderInfo;
    },
    showAlert({ title, msg }: { title?: string; msg: string }) {
      this.alertTitle = title || '';
      this.alertMsg = msg;
    },
    closeAlert() {
      this.alertTitle = '';
      this.alertMsg = '';
    },
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
  },
  async mounted() {
    const plan = storage.session.get('goodsForOrder') as unknown as TypePlan;
    const orderInfo = new Order(plan);
    const planOptions = await orderInfo.getPlanOptions();
    const planGuide = await orderInfo.getPlanGuide();
    this.orderInfo = orderInfo;
    this.planOptions = planOptions;
    this.planGuide = planGuide;
    storage.session.remove('goodsForOrder');
  },
};
</script>

<style lang="scss" scoped>
.order {
  max-width: 800px;
  margin: 0 auto;
}
</style>
