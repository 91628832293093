
export default {
  name: 'Alert',
  props: {
    title: {
      type: String,
      required: false,
    },
    msg: {
      type: String,
      required: true,
    },
    btns: {
      type: Object as () => Array<{ label: string; handler: () => void }>,
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
      buttons: [],
    };
  },
  watch: {
    msg: {
      immediate: true,
      handler() {
        this.setPopupShow();
      },
    },
    btns: {
      immediate: true,
      handler() {
        this.setButtons();
      },
    },
  },
  methods: {
    setButtons() {
      if (!this.btns) {
        this.buttons = [{ label: '확인', handler: () => this.$emit('close') }];
      } else {
        this.buttons = this.btns;
      }
    },
    setPopupShow() {
      const isShow = Boolean(this.msg);
      this.isShow = isShow;
      document.body.style.overflow = isShow ? 'hidden' : '';
    },
  },
};
