import { render } from "./AdditionalBenefit.vue?vue&type=template&id=958f20f2&scoped=true"
import script from "./AdditionalBenefit.vue?vue&type=script&lang=ts"
export * from "./AdditionalBenefit.vue?vue&type=script&lang=ts"

import "./AdditionalBenefit.vue?vue&type=style&index=0&id=958f20f2&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-958f20f2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "958f20f2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('958f20f2', script)) {
    api.reload('958f20f2', script)
  }
  
  module.hot.accept("./AdditionalBenefit.vue?vue&type=template&id=958f20f2&scoped=true", () => {
    api.rerender('958f20f2', render)
  })

}

script.__file = "src/page/order/sections/AdditionalBenefit.vue"

export default script