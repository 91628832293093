import { render } from "./Alert.vue?vue&type=template&id=523931db&scoped=true"
import script from "./Alert.vue?vue&type=script&lang=ts"
export * from "./Alert.vue?vue&type=script&lang=ts"

import "./Alert.vue?vue&type=style&index=0&id=523931db&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-523931db"
/* hot reload */
if (module.hot) {
  script.__hmrId = "523931db"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('523931db', script)) {
    api.reload('523931db', script)
  }
  
  module.hot.accept("./Alert.vue?vue&type=template&id=523931db&scoped=true", () => {
    api.rerender('523931db', render)
  })

}

script.__file = "src/page/order/components/Alert.vue"

export default script