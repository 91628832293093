
import stringTool from '@/common/stringTool';
import numberTool from '@/common/numberTool';
import {
  /* CONST */
  BENEFIT_TYPE,
  BENEFIT_OPTIONS,
  /* TYPE */
  TypeBenefitType,
} from '../modules/interface';
import { IOrderBenefit } from '../modules/OrderBenefit';

export default {
  name: 'AdditionalBenefit',
  emits: ['updateAdditionalDc'],
  props: {
    benefitInfo: {
      type: Object as () => IOrderBenefit,
      required: true,
    },
    useRepurchaseBenefit: {
      type: Boolean,
      required: true,
    },
    additionalDc: {
      type: Object as () => { type: TypeBenefitType; amount: number; couponIdx: number },
      required: true,
    },
  },
  data() {
    return {
      BENEFIT_TYPE,
      selected: null,
      availablePoints: 0,
      availableCoupons: [],
      usePoint: 0,
      usePointAll: false,
      useCouponIdx: '0',
      toastMsg: '',
      toastTimer: null,
    };
  },
  computed: {
    showPointOption() {
      return this.benefitInfo.option === BENEFIT_OPTIONS.BOTH || this.benefitInfo.option === BENEFIT_OPTIONS.POINT;
    },
    showCouponOption() {
      return this.benefitInfo.option === BENEFIT_OPTIONS.BOTH || this.benefitInfo.option === BENEFIT_OPTIONS.COUPON;
    },
    limitAmount() {
      return this.useRepurchaseBenefit ? this.benefitInfo.maxAmountRepurchase : this.benefitInfo.maxAmount;
    },
    limitPercent() {
      return this.useRepurchaseBenefit ? this.benefitInfo.maxPercentRepurchase : this.benefitInfo.maxPercent;
    },
    canUse() {
      return this.availablePoints > this.limitAmount ? this.limitAmount : this.availablePoints;
    },
    availablePointDesc() {
      return this.limitAmount > 0
        ? stringTool.replaceVars('{{1}}P 사용가능 (결제액의 {{2}}%, 최대 {{3}}P 한도)', [
            numberTool.format(this.canUse, null),
            numberTool.format(this.limitPercent, null),
            numberTool.format(this.limitAmount, null),
          ])
        : '포인트 사용이 불가능한 수강권입니다.';
    },
  },
  watch: {
    useRepurchaseBenefit() {
      if (this.selected !== BENEFIT_TYPE.POINT) return;
      if (!(this.usePointAll || this.canUse < this.usePoint)) return;
      this.updateUsePoint(this.canUse);
    },
    useCouponIdx() {
      this.updateUseCoupon(this.useCouponIdx);
    },
    selected() {
      if (this.selected === BENEFIT_TYPE.POINT) {
        this.updateUsePoint(this.usePoint);
      } else if (this.selected === BENEFIT_TYPE.COUPON) {
        this.updateUseCoupon(this.useCouponIdx);
        this.closeToast();
      }
    },
  },
  methods: {
    updateSelectedType(type: TypeBenefitType) {
      this.selected = type;
    },
    updateUseCoupon(couponIdx: number) {
      const selectedCoupon = this.availableCoupons?.find((coupon) => coupon.dcsIdx === couponIdx);
      const amount = selectedCoupon?.selectedPlanDiscountMoney || 0;
      const additionalDc = { type: BENEFIT_TYPE.COUPON, couponIdx, amount };
      this.$emit('update:additionalDc', additionalDc);
    },
    updateUsePoint(amount: number) {
      const additionalDc = { type: BENEFIT_TYPE.POINT, amount };
      this.$emit('update:additionalDc', additionalDc);
      this.usePoint = amount;
      this.$refs.pointInput.value = amount;
      this.toggleToast();
    },
    checkUpdateUsePoint(e) {
      const newUsePoint = Number(e.target.value);
      if (newUsePoint !== this.usePoint) {
        this.updateUsePoint(newUsePoint);
      }
    },
    toggleUsePointAll(e) {
      const usePointAll = e.target.checked;
      const amount = usePointAll ? this.canUse : 0;
      this.usePointAll = usePointAll;
      this.updateUsePoint(amount);
    },
    inputPoint(e) {
      let amount = Number(`${e.target.value}`.replace(/(^0+)/, ''));
      if (amount < this.canUse) {
        this.usePointAll = false;
      } else {
        this.usePointAll = true;
        amount = this.canUse;
      }
      e.target.value = `${amount}`;
    },
    toggleToast() {
      if (this.usePoint <= 0) {
        this.closeToast();
        return;
      }
      if (!this.toastMsg) {
        this.openToast();
        return;
      }
      this.closeToast();
      this.$nextTick(() => this.openToast());
    },
    openToast() {
      this.toastMsg = `${this.usePoint.toLocaleString()}P 사용되었습니다.`;
      this.toastTimer = setTimeout(this.closeToast.bind(this), 1500);
    },
    closeToast() {
      clearTimeout(this.toastTimer);
      this.toastMsg = '';
      this.toastTimer = null;
    },
  },
  async mounted() {
    if (this.benefitInfo.option === BENEFIT_OPTIONS.BOTH) {
      this.selected = BENEFIT_TYPE.POINT;
      this.availablePoints = await this.benefitInfo.getAvailablePoints();
      this.availableCoupons = await this.benefitInfo.getAvailableCoupons();
    } else if (this.benefitInfo.option === BENEFIT_OPTIONS.POINT) {
      this.selected = BENEFIT_TYPE.POINT;
      this.availablePoints = await this.benefitInfo.getAvailablePoints();
    } else if (this.benefitInfo.option === BENEFIT_OPTIONS.COUPON) {
      this.selected = BENEFIT_TYPE.COUPON;
      this.availableCoupons = await this.benefitInfo.getAvailableCoupons();
    }
  },
};
