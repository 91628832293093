<template>
  <div class="post-search">
    <button class="close" @click="$emit('close')" />
    <div class="wrapper">
      <VueDaumPostcode class="content" @complete="onComplete" />
    </div>
  </div>
</template>

<script lang="ts">
/* https://postcode.map.daum.net/guide#attributes */
import { VueDaumPostcode, VueDaumPostcodeCompleteResult } from 'vue-daum-postcode';

const ADDRESS_TYPE = {
  ROAD: 'R',
  JIBUN: 'J',
} as const;

export default {
  name: 'PostCodeSearch',
  components: {
    VueDaumPostcode,
  },
  methods: {
    onComplete(result: VueDaumPostcodeCompleteResult) {
      const {
        addressType,
        address,
        postcode,
        zonecode,
        bname, // 법정동, 법정리 이름
        buildingName,
      } = result;
      let extraAddress = '';
      if (addressType === ADDRESS_TYPE.ROAD) {
        if (bname) {
          extraAddress += bname;
        }
        if (buildingName) {
          extraAddress += extraAddress ? `, ${buildingName}` : buildingName;
        }
      }
      const zipCodeData = postcode || zonecode;
      const addressData = `${address} ${extraAddress ? `(${extraAddress})` : ''}`;
      this.$emit('complete', { zipCodeData, addressData });
    },
  },
};
</script>

<style lang="scss" scoped>
.post-search {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
}
.wrapper {
  position: relative;
  width: calc(100% - 40px);
  height: 350px;
  margin: 0 auto;
  &::before {
    content: '로드 중입니다...';
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background-color: #ffffff;
  }
}

.content::v-deep {
  height: 100%;
  .vue-daum-postcode-container {
    height: 100% !important;
  }
}
.close {
  width: 1.88em;
  height: 1.88em;
  background: url(//res.tutoring.co.kr/res/img//icon/close-type02--black.svg) no-repeat;
  background-size: 50%;
  background-position: center;
  background-color: #fff;
  border-radius: 100em;
  padding: 1.2em;
  margin: -20px 20px 20px 0;
  align-self: end;
}
</style>
