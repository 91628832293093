import { render } from "./Loading.vue?vue&type=template&id=12603bfb&scoped=true"
import script from "./Loading.vue?vue&type=script&lang=ts"
export * from "./Loading.vue?vue&type=script&lang=ts"

import "./Loading.vue?vue&type=style&index=0&id=12603bfb&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-12603bfb"
/* hot reload */
if (module.hot) {
  script.__hmrId = "12603bfb"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('12603bfb', script)) {
    api.reload('12603bfb', script)
  }
  
  module.hot.accept("./Loading.vue?vue&type=template&id=12603bfb&scoped=true", () => {
    api.rerender('12603bfb', render)
  })

}

script.__file = "src/page/order/components/Loading.vue"

export default script