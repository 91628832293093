import api from '@/common/api';
import { PAY_METHOD, TypePayMethod, TypePlan, TypeRegisterCard, TypeSubscribeCard } from './interface';

export interface IOrderPay {
  method: TypePayMethod;
  agree: boolean;
  isSubscribe: boolean;
  isDollar: 0 | 1;
  originPrice: number;
  salePrice: number;
  discountedPercent: number;
  discountedAmount: number;
  payPerMonth: number;
  earningPoints: number;
  card: TypeRegisterCard;
  subscribe(cpIdx: number): Promise<{
    success: boolean;
    data: unknown;
  }>;
  getSubscribeCardInfo(): Promise<TypeSubscribeCard>;
  setSubscribeCardInfo(): Promise<{
    success: boolean;
    data: unknown;
  }>;
  getSubscribePolicy(type: number): Promise<{
    success: boolean;
    title: string;
    content: string;
  }>;
  checkCardNumber(): boolean;
  checkCardExp(): boolean;
  checkCardPwd(): boolean;
  checkCardBirth(): boolean;
  checkAgree(): boolean;
}

export default class OrderPay implements IOrderPay {
  private _method: TypePayMethod;

  private _agree: boolean;

  private _isSubscribe: boolean;

  private _isDollar: 0 | 1;

  private _originPrice: number;

  private _salePrice: number;

  private _discountedPercent: number;

  private _discountedAmount: number;

  private _payPerMonth: number;

  private _earningPoints: number;

  private _card: TypeRegisterCard;

  get method(): TypePayMethod {
    return this._method;
  }

  set method(method: TypePayMethod) {
    this._method = method;
  }

  get agree(): boolean {
    return this._agree;
  }

  set agree(agree: boolean) {
    this._agree = agree;
  }

  get isSubscribe(): boolean {
    return this._isSubscribe;
  }

  get isDollar(): 0 | 1 {
    return this._isDollar;
  }

  get originPrice(): number {
    return this._originPrice;
  }

  get salePrice(): number {
    return this._salePrice;
  }

  get discountedPercent(): number {
    return this._discountedPercent;
  }

  get discountedAmount(): number {
    return this._discountedAmount;
  }

  get payPerMonth(): number {
    return this._payPerMonth;
  }

  get earningPoints(): number {
    return this._earningPoints;
  }

  get card(): TypeRegisterCard {
    return this._card;
  }

  set card(card: TypeRegisterCard) {
    this._card = card;
  }

  constructor(planInfo: TypePlan) {
    const {
      isSubscribePlan: isSubscribe,
      isDollar,
      origin_money: originPrice,
      money: salePrice,
      discount: discountedPercent,
      moneyPerMonth: payPerMonth,
      bonusPointPercentage: earningPoints,
    } = planInfo;

    this._method = isSubscribe ? PAY_METHOD.SUBSCRIBE : PAY_METHOD.CARD;
    this._agree = false;
    this._isSubscribe = isSubscribe;
    this._isDollar = isDollar;
    this._originPrice = Number(originPrice);
    this._salePrice = Number(salePrice);
    this._discountedPercent = Number(discountedPercent);
    this._discountedAmount = this._originPrice - this._salePrice;
    this._payPerMonth = Number(payPerMonth);
    this._earningPoints = earningPoints;
    this._card = {
      cardNumber: ['', '', '', ''],
      expirationMonth: '',
      expirationYear: '',
      pwd2Digit: '',
      birth: '',
    };
  }

  async subscribe(cpIdx: number): Promise<{
    success: boolean;
    data: unknown;
  }> {
    try {
      const amount = this._salePrice;
      const {
        result,
        message,
        data: {
          moneyTransaction: { idx },
        },
      } = await api.post('/app/users/plan/subscribe/payment', { amount, cpIdx });
      if (result !== 2000) throw new Error(message);
      return { success: true, data: `${idx}` };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        data: {
          title: '구독 등록 실패',
          msg: (error as Error).message || '구독 등록 중 서버 에러가 발생했습니다. 관리자에게 문의해주세요.',
        },
      };
    }
  }

  async getSubscribeCardInfo(): Promise<TypeSubscribeCard> {
    try {
      const {
        data: { subscribeCardInfo },
      } = await api.get('/app/users/plan/subscribe/billkey');
      if (!subscribeCardInfo || subscribeCardInfo.length === 0) throw new Error('카드 정보 없음');
      return subscribeCardInfo;
    } catch (error) {
      console.error(error);
      return {
        cardName: '',
        cardNumber: '',
      };
    }
  }

  async setSubscribeCardInfo(): Promise<{
    success: boolean;
    data: unknown;
  }> {
    const { cardNumber, expirationMonth, expirationYear, pwd2Digit, birth } = this.card;

    try {
      const { result, message } = await api.post('/app/users/plan/subscribe/billkey', {
        cardNumber: cardNumber.join(''),
        expirationMonth,
        expirationYear: `20${expirationYear}`,
        pwd2Digit,
        birth,
      });
      if (result !== 2000) throw new Error(message);
      return {
        success: true,
        data: { title: '', msg: '' },
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        data: {
          title: '카드 등록 실패',
          msg: (error as Error).message || '카드 등록 중 서버 에러가 발생했습니다. 관리자에게 문의해주세요.',
        },
      };
    }
  }

  async getSubscribePolicy(type: number): Promise<{
    success: boolean;
    title: string;
    content: string;
  }> {
    try {
      const {
        result,
        data: { agreement },
      } = await api.get(`/app/etc/agreement/${type}`);
      if (result !== 2000) throw new Error('서버 응답 실패');
      const { title, content } = agreement;
      return { success: true, title, content };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        title: '약관정보 불러오기 실패',
        content: '약관정보를 가져오는 중 서버 에러가 발생했습니다. 관리자에게 문의해주세요.',
      };
    }
  }

  checkCardNumber(): boolean {
    const { cardNumber } = this._card;
    const cardNumberString = cardNumber.join('');
    if (cardNumberString) {
      const numberEXP = /^[0-9]*$/;
      if (!numberEXP.test(cardNumberString) || !(cardNumberString.length > 0 && cardNumberString.length <= 16)) {
        return false;
      }
    }
    return true;
  }

  checkCardExp(): boolean {
    const { expirationYear, expirationMonth } = this._card;
    if (expirationYear && expirationMonth) {
      const numberEXP = /^[0-9]*$/;
      if (
        !numberEXP.test(expirationYear) ||
        !(expirationYear.length > 0 && expirationYear.length <= 2) ||
        !numberEXP.test(expirationMonth) ||
        !(expirationMonth.length > 0 && expirationMonth.length <= 2)
      ) {
        return false;
      }
    }
    return true;
  }

  checkCardPwd(): boolean {
    const { pwd2Digit } = this._card;
    if (pwd2Digit) {
      const numberEXP = /^[0-9]*$/;
      if (!numberEXP.test(pwd2Digit) || !(pwd2Digit.length > 0 && pwd2Digit.length <= 2)) {
        return false;
      }
    }
    return true;
  }

  checkCardBirth(): boolean {
    const { birth } = this._card;
    if (birth) {
      const numberEXP = /^[0-9]*$/;
      if (birth.length < 6 || birth.length > 10 || !numberEXP.test(birth)) {
        return false;
      }
    }
    return true;
  }

  checkAgree(): boolean {
    if (!this._agree) return false;
    return true;
  }
}
