import { render } from "./OrderSheet.vue?vue&type=template&id=61d62f2a&scoped=true"
import script from "./OrderSheet.vue?vue&type=script&lang=ts"
export * from "./OrderSheet.vue?vue&type=script&lang=ts"

import "./OrderSheet.vue?vue&type=style&index=0&id=61d62f2a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-61d62f2a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "61d62f2a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('61d62f2a', script)) {
    api.reload('61d62f2a', script)
  }
  
  module.hot.accept("./OrderSheet.vue?vue&type=template&id=61d62f2a&scoped=true", () => {
    api.rerender('61d62f2a', render)
  })

}

script.__file = "src/page/order/sections/OrderSheet.vue"

export default script