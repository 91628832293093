/* eslint-disable no-param-reassign */
export default {
  // 반드시 숫자타입을 리턴, 실패시 0 리턴
  format(num, decimal) {
    if (num && !Number.isNaN(num)) {
      if (!decimal) decimal = 0;
      num = Number(Number(num).toFixed(decimal)).toLocaleString();
      return num;
    }
    return 0;
  },

  padding(n, width, symbol) {
    symbol = symbol || '0';
    n += '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(symbol) + n;
  },
};
