<template>
  <section class="options" v-if="optionList.length > 0">
    <h4 class="title">기종 색상 선택</h4>
    <ul class="list">
      <li class="item" v-for="opt in optionList" :key="opt.idx">
        <input type="radio" :id="opt.code" :value="opt.idx" v-model="selected" />
        <label class="label" :for="opt.code">
          <img v-if="Boolean(opt.image)" :src="opt.image" alt="" />
          <span>{{ opt.title }}</span>
        </label>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import {
  /* CONST */
  PLAN_CODE,
  /* TYPE */
  TypeOption,
  TypePlanCode,
} from '../modules/interface';
import { IOrderItem } from '../modules/OrderItem';

export default {
  name: 'ComplexOptions',
  props: {
    itemInfo: {
      type: Object as () => IOrderItem,
      required: true,
    },
  },
  data() {
    return {
      optionList: [] as Array<TypeOption>,
      selected: 0 as number,
    };
  },
  watch: {
    selected() {
      const selectedOption = this.optionList.find((opt) => opt.idx === this.selected);
      // eslint-disable-next-line vue/no-mutating-props
      this.itemInfo.options = [selectedOption];
    },
  },
  created() {
    const { code, options } = this.itemInfo;
    const showComplexOptions: Array<TypePlanCode> = [PLAN_CODE.EVENT_2021FOCUSPACK];
    if (showComplexOptions.includes(code)) {
      this.optionList = [...options];
      this.selected = options[0].idx;
    }
  },
};
</script>

<style lang="scss" scoped>
.options {
  padding: 22px 10px 12px;
}

.title {
  font-weight: 700;
  font-size: 1.145rem;
  line-height: 2.15;
  border-radius: 8px;
  background-color: #f3f3f3;
  text-indent: 14px;
  margin-bottom: 16px;
}

.item {
  & + .item {
    border-top: solid 1px #f3f2f2;
  }
  input[type='radio'] {
    display: none;
    & + .label::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15px;
      margin-top: -12px;
      width: 28px;
      height: 28px;
      background-image: url('//res.tutoring.co.kr/res/images/spr/ico_comm_181220.png');
      background-repeat: no-repeat;
      background-size: 200px 160px;
      background-position: -170px 0;
    }
    &:checked + .label {
      color: #00abff;
      &::before {
        background-position: -170px -30px;
      }
    }
  }
}
.label {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 0 16px 48px;
  font-weight: 700;
  img {
    margin-right: 7px;
    margin-left: 10px;
  }
}
</style>
