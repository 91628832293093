import { render } from "./TopImage.vue?vue&type=template&id=ed474182&scoped=true"
import script from "./TopImage.vue?vue&type=script&lang=ts"
export * from "./TopImage.vue?vue&type=script&lang=ts"

import "./TopImage.vue?vue&type=style&index=0&id=ed474182&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-ed474182"
/* hot reload */
if (module.hot) {
  script.__hmrId = "ed474182"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ed474182', script)) {
    api.reload('ed474182', script)
  }
  
  module.hot.accept("./TopImage.vue?vue&type=template&id=ed474182&scoped=true", () => {
    api.rerender('ed474182', render)
  })

}

script.__file = "src/page/order/sections/TopImage.vue"

export default script