import api from '@/common/api';
import {
  /* CONST */
  DELIVERY_TYPE,
  /* TYPE */
  TypePlan,
  TypeDeliveryType,
} from './interface';

export interface IOrderDelivery {
  type: TypeDeliveryType;
  recipient: string;
  mobile: string;
  tel: string;
  zipCode: string;
  address1: string;
  address2: string;
  message: string;
  requestMobileCheck(phone: string): Promise<{
    success: boolean;
    data: string;
  }>;
  confirmMobileCheck(
    phone: string,
    code: string,
  ): Promise<{
    success: boolean;
    data: string;
  }>;
  checkRecipient(): boolean;
  checkMobile(): boolean;
  checkTel(): boolean;
  checkAddress(): boolean;
}

export default class OrderDelivery implements IOrderDelivery {
  private _type: TypeDeliveryType;

  private _recipient: string;

  private _mobile: string;

  private _tel: string;

  private _zipCode: string;

  private _address1: string;

  private _address2: string;

  private _message: string;

  get type(): TypeDeliveryType {
    return this._type;
  }

  get recipient(): string {
    return this._recipient;
  }

  set recipient(recipient: string) {
    this._recipient = recipient;
  }

  get mobile(): string {
    return this._mobile;
  }

  set mobile(mobile: string) {
    this._mobile = mobile;
  }

  get tel(): string {
    return this._tel;
  }

  set tel(tel: string) {
    this._tel = tel;
  }

  get zipCode(): string {
    return this._zipCode;
  }

  set zipCode(zipCode: string) {
    this._zipCode = zipCode;
  }

  get address1(): string {
    return this._address1;
  }

  set address1(address1: string) {
    this._address1 = address1;
  }

  get address2(): string {
    return this._address2;
  }

  set address2(address2: string) {
    this._address2 = address2;
  }

  get message(): string {
    return this._message;
  }

  set message(message: string) {
    this._message = message;
  }

  constructor(planInfo: TypePlan) {
    const {
      additionalInfo: { needAddress: type },
    } = planInfo;

    this._type = type;
    this._recipient = '';
    this._mobile = '';
    this._tel = '';
    this._zipCode = '';
    this._address1 = '';
    this._address2 = '';
    this._message = '';
  }

  async requestMobileCheck(phone: string): Promise<{
    success: boolean;
    data: string;
  }> {
    try {
      const {
        result,
        data: { phoneCodeExpiredDt },
      } = await api.post('/open/app/phoneCheckRequest', { phone });
      if (result !== 2000) throw new Error('인증 번호 발송 실패');
      return { success: true, data: phoneCodeExpiredDt };
    } catch (error) {
      console.error(error);
      return { success: false, data: '' };
    }
  }

  async confirmMobileCheck(
    phone: string,
    code: string,
  ): Promise<{
    success: boolean;
    data: string;
  }> {
    try {
      const { result, message } = await api.post('/open/app/phoneCheckConfirm', {
        phone,
        code,
      });
      if (result !== 2000) throw new Error(message);
      return { success: true, data: '휴대폰 번호 인증이 완료되었습니다.' };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        data: (error as Error).message || '휴대폰 번호 인증 실패',
      };
    }
  }

  checkRecipient(): boolean {
    if (!this._recipient) return false;
    return true;
  }

  checkMobile(): boolean {
    if (this._type !== DELIVERY_TYPE.SD) {
      if (!this._mobile) {
        return false;
      }
    }
    return true;
  }

  checkTel(): boolean {
    const tel = this._tel;
    if (tel) {
      const numberEXP = /^[0-9]+$/;
      if (!numberEXP.test(tel) || !(tel.length >= 9 && tel.length <= 11)) {
        return false;
      }
    }
    return true;
  }

  checkAddress(): boolean {
    if (this._type !== DELIVERY_TYPE.SP) {
      if (!this._zipCode || !this._address1) {
        return false;
      }
    }
    return true;
  }
}
