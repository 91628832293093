import { render } from "./PostCodeSearch.vue?vue&type=template&id=0e1306c6&scoped=true"
import script from "./PostCodeSearch.vue?vue&type=script&lang=ts"
export * from "./PostCodeSearch.vue?vue&type=script&lang=ts"

import "./PostCodeSearch.vue?vue&type=style&index=0&id=0e1306c6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0e1306c6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0e1306c6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0e1306c6', script)) {
    api.reload('0e1306c6', script)
  }
  
  module.hot.accept("./PostCodeSearch.vue?vue&type=template&id=0e1306c6&scoped=true", () => {
    api.rerender('0e1306c6', render)
  })

}

script.__file = "src/page/order/components/PostCodeSearch.vue"

export default script