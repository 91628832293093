
import { PLAN_CODE, TUTOR_TYPE } from '../modules/interface';
import { IOrder } from '../modules/Order';
import { OrderSheet, UserInfoBox, PayMethod, PaySubscribe } from '../sections';

export default {
  name: 'SubscribePlan',
  components: {
    OrderSheet,
    UserInfoBox,
    PayMethod,
    PaySubscribe,
  },
  emits: ['showAlert', 'setIsLoading'],
  props: {
    orderInfo: {
      type: Object as () => IOrder,
      required: true,
    },
  },
  computed: {
    topImageSrc() {
      if (this.orderInfo.item.code.includes(PLAN_CODE.ALLPASS)) {
        return `//res.tutoring.co.kr/res/images/evt/202011/allpass/purchase_bnr_${
          this.orderInfo.item.tutorType === TUTOR_TYPE.GLOBAL ? 'global' : 'native'
        }_${this.orderInfo.item.lessonMinutes}.png`;
      }
      return '';
    },
  },
  methods: {
    checkPay(): boolean {
      console.log(this.orderInfo.pay);
      if (!this.orderInfo.pay.checkCardNumber()) {
        this.$emit('showAlert', { msg: '카드번호를 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkCardExp()) {
        this.$emit('showAlert', { msg: '카드 유효기간을 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkCardPwd()) {
        this.$emit('showAlert', { msg: '카드 비밀번호를 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkCardBirth()) {
        this.$emit('showAlert', { msg: '생년월일 또는 사업자 등록번호를 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkAgree()) {
        this.$emit('showAlert', { msg: '결제 동의를 확인해주세요' });
        return false;
      }
      return true;
    },
    async goToPay() {
      if (!this.checkPay()) return;
      this.$emit('setIsLoading', true);
      const { success, data } = await this.orderInfo.submitSubscribe();
      if (!success) {
        const { title, msg } = data as { title: string; msg: string };
        this.$emit('setIsLoading', false);
        this.$emit('showAlert', { title, msg });
        return;
      }
      this.$emit('setIsLoading', false);
      this.$router.push(`${window.location.origin}/home/order_result?mt_idx=${data}`);
    },
  },
};
