<template>
  <!-- 상단 이미지 -->
  <div
    v-if="Boolean(topImageSrc)"
    style="text-align: center; background: linear-gradient(142.51deg, #7063f9 29.38%, #ae63f9 100%)"
  >
    <img
      :src="topImageSrc"
      style="width: 100%; height: 100%; max-width: 400px; padding: 30px 56px 26px; box-sizing: border-box"
      alt=""
    />
  </div>
  <!-- 주문 내역 -->
  <section class="section">
    <h4 class="section__title">주문 내역</h4>
    <OrderSheet :itemInfo="orderInfo.item" :payInfo="orderInfo.pay" :showPlanInfo="true" />
  </section>
  <!-- 정보 입력 -->
  <section class="section">
    <h4 class="section__title">정보 입력</h4>
    <UserInfoBox />
  </section>
  <!-- 결제 수단 -->
  <section class="section">
    <h4 class="section__title">결제수단</h4>
    <PayMethod :payInfo="orderInfo.pay" />
  </section>
  <!-- 카드 등록 -->
  <section class="section">
    <h4 class="section__title">카드등록</h4>
    <PaySubscribe :payInfo="orderInfo.pay" />
  </section>
  <!-- 주문 내역 확인 -->
  <section class="section">
    <h4 class="section__title">주문 내역 확인</h4>
    <OrderSheet :itemInfo="orderInfo.item" :payInfo="orderInfo.pay" :showPlanInfo="false" @goToPay="goToPay" />
  </section>
</template>

<script lang="ts">
import { PLAN_CODE, TUTOR_TYPE } from '../modules/interface';
import { IOrder } from '../modules/Order';
import { OrderSheet, UserInfoBox, PayMethod, PaySubscribe } from '../sections';

export default {
  name: 'SubscribePlan',
  components: {
    OrderSheet,
    UserInfoBox,
    PayMethod,
    PaySubscribe,
  },
  emits: ['showAlert', 'setIsLoading'],
  props: {
    orderInfo: {
      type: Object as () => IOrder,
      required: true,
    },
  },
  computed: {
    topImageSrc() {
      if (this.orderInfo.item.code.includes(PLAN_CODE.ALLPASS)) {
        return `//res.tutoring.co.kr/res/images/evt/202011/allpass/purchase_bnr_${
          this.orderInfo.item.tutorType === TUTOR_TYPE.GLOBAL ? 'global' : 'native'
        }_${this.orderInfo.item.lessonMinutes}.png`;
      }
      return '';
    },
  },
  methods: {
    checkPay(): boolean {
      console.log(this.orderInfo.pay);
      if (!this.orderInfo.pay.checkCardNumber()) {
        this.$emit('showAlert', { msg: '카드번호를 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkCardExp()) {
        this.$emit('showAlert', { msg: '카드 유효기간을 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkCardPwd()) {
        this.$emit('showAlert', { msg: '카드 비밀번호를 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkCardBirth()) {
        this.$emit('showAlert', { msg: '생년월일 또는 사업자 등록번호를 입력해주세요.' });
        return false;
      }
      if (!this.orderInfo.pay.checkAgree()) {
        this.$emit('showAlert', { msg: '결제 동의를 확인해주세요' });
        return false;
      }
      return true;
    },
    async goToPay() {
      if (!this.checkPay()) return;
      this.$emit('setIsLoading', true);
      const { success, data } = await this.orderInfo.submitSubscribe();
      if (!success) {
        const { title, msg } = data as { title: string; msg: string };
        this.$emit('setIsLoading', false);
        this.$emit('showAlert', { title, msg });
        return;
      }
      this.$emit('setIsLoading', false);
      this.$router.push(`${window.location.origin}/home/order_result?mt_idx=${data}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin-top: 28px;
  &__title {
    display: block;
    padding: 0 20px;
    font-size: 24px;
    line-height: 36px;
  }
}
</style>
