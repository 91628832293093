
import {
  /* CONST */
  PLAN_CODE,
  TUTOR_LABEL,
  TUTOR_TYPE,
  /* TYPE */
  TypePlan,
  TypeTutorLabel,
} from '../modules/interface';
import { IOrderItem } from '../modules/OrderItem';

type TypeParsedOption = {
  cpIdx: number;
  tutor: TypeTutorLabel;
  monthCnt: number;
  lessonPerWeek: number;
  minute: number;
  lessonCnt: number;
  moneyPerMonth: number;
  originMoneyPerMonth: number;
  isBest: boolean;
  product?: Array<string>;
};

export default {
  name: 'PlanOptions',
  emits: ['changePlan'],
  props: {
    itemInfo: {
      type: Object as () => IOrderItem,
      required: true,
    },
    planOptions: {
      type: Object as () => Array<TypePlan>,
      required: true,
    },
  },
  computed: {
    isAlphaPlan() {
      return this.itemInfo.code === PLAN_CODE.ALPHA_PLAN;
    },
    isEngTechPlan() {
      return this.itemInfo.code === PLAN_CODE.JICDING_ENGLISHTECH;
    },
  },
  data() {
    return {
      optionGroups: [] as Array<Array<TypeParsedOption>>,
      selected: this.itemInfo.cpIdx as Number,
    };
  },
  watch: {
    selected() {
      const plan = this.planOptions.find((opt) => opt.cp_idx === this.selected);
      if (plan) {
        this.$emit('changePlan', plan);
      }
    },
  },
  created() {
    if (this.itemInfo.code === PLAN_CODE.ALPHA_PLAN) {
      this.optionGroups = this.planOptions.reduce(
        (optList, option) => {
          const {
            cp_idx: cpIdx,
            month_cnt: monthCnt,
            lessonPerWeek,
            minute,
            t_type: tutorType,
            lesson_cnt: lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
          } = option;
          const type = `${monthCnt}${minute}`;
          const tutor = TUTOR_LABEL[tutorType];
          const isBest = type === '620' && tutorType === TUTOR_TYPE.GLOBAL;
          let idx = -1;
          if (type === '610') idx = 0;
          else if (type === '620') idx = 1;
          else if (type === '310') idx = 2;
          else if (type === '320') idx = 3;
          optList[idx].push({
            cpIdx,
            tutor,
            isBest,
            monthCnt,
            lessonPerWeek,
            minute,
            lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
          });
          return optList;
        },
        [[], [], [], []] as Array<Array<TypeParsedOption>>,
      );
    } else if (this.itemInfo.code === PLAN_CODE.JICDING_ENGLISHTECH) {
      this.optionGroups = this.planOptions.reduce(
        (optList, option) => {
          const {
            cp_idx: cpIdx,
            month_cnt: monthCnt,
            lessonPerWeek,
            minute,
            t_type: tutorType,
            lesson_cnt: lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
            options,
          } = option;
          const type = `${tutorType}${minute}`;
          const tutor = TUTOR_LABEL[tutorType];
          const isBest = false;
          const product = options.map((opt) => opt.title);
          let idx = -1;
          if (type === 'C10') idx = 0;
          else if (type === 'B10') idx = 1;
          else if (type === 'C20') idx = 2;
          else if (type === 'B20') idx = 3;
          optList[idx].push({
            cpIdx,
            tutor,
            isBest,
            monthCnt,
            lessonPerWeek,
            minute,
            lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
            product,
          });
          return optList;
        },
        [[], [], [], []] as Array<Array<TypeParsedOption>>,
      );
    }
  },
};
