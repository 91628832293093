
import stringTool from '@/common/stringTool';
import { PAY_METHOD } from '../modules/interface';
import { IOrderPay } from '../modules/OrderPay';

export default {
  name: 'PayMethod',
  props: {
    payInfo: {
      type: Object as () => IOrderPay,
      required: true,
    },
  },
  data() {
    return {
      PAY_METHOD,
      selected: this.payInfo.isSubscribe ? PAY_METHOD.SUBSCRIBE : PAY_METHOD.CARD,
    };
  },
  computed: {
    bonusPointDesc() {
      return stringTool.replaceVars('전체 구매액의 {{1}}% 포인트로 적립', [this.payInfo.earningPoints]);
    },
  },
  watch: {
    selected() {
      this.updatePayMethod();
    },
    'payInfo.method': {
      handler() {
        if (!this.payInfo.isSubscribe) {
          this.updatePayMethod();
        }
      },
    },
  },
  methods: {
    updatePayMethod() {
      // eslint-disable-next-line vue/no-mutating-props
      this.payInfo.method = this.selected;
    },
  },
};
