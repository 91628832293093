
import { PLAN_CODE, TypePlanCode } from '../modules/interface';

type TypeTopImage = {
  banner?: { img: string; background: string };
  benefit?: Array<string>;
  noFrame?: boolean;
  message?: string;
  background?: { img: string; color: string };
};

type TypeTopImages = {
  [key in TypePlanCode]: TypeTopImage;
};

export default {
  name: 'TopImage',
  props: {
    code: {
      type: String as () => TypePlanCode,
      required: true,
    },
  },
  data() {
    return {
      topImage: {} as TypeTopImage,
    };
  },
  mounted() {
    const topImage: TypeTopImages = {
      [PLAN_CODE.BASIC]: {
        benefit: ['//res.tutoring.co.kr/res/images/jiayou/a_bnr/purchase_txt_jiayoupass.png'],
      },
      [PLAN_CODE.DAWATDA_PASS]: {
        banner: {
          img: '//res.tutoring.co.kr/res/images/jiayou/plan/promo/da/da_purchase_img.jpg',
          background: '#42009E',
        },
        benefit: [
          '//res.tutoring.co.kr/res/images/jiayou/a_bnr/purchase_txt_da_w360_01.png',
          '//res.tutoring.co.kr/res/images/jiayou/a_bnr/purchase_txt_da_01_w768.png',
        ],
      },
      [PLAN_CODE.REAL_BEGINNER_PLAN]: {
        benefit: ['//res.tutoring.co.kr/res/images/jiayou/a_bnr/purchase_txt_jin.png'],
      },
      [PLAN_CODE.ALLPASS]: {
        benefit: [
          '//res.tutoring.co.kr/res/images/evt/202011/allforu/purchase_txt_allforu_w360.png',
          '//res.tutoring.co.kr/res/images/evt/202011/allforu/purchase_txt_allforu_w768.png',
        ],
      },
      [PLAN_CODE.ALLPASS_NEW]: {
        benefit: [
          '//res.tutoring.co.kr/res/images/evt/202011/allforu/purchase_txt_allforu_w360.png',
          '//res.tutoring.co.kr/res/images/evt/202011/allforu/purchase_txt_allforu_w768.png',
        ],
      },
      [PLAN_CODE.ALLPASS_REPURCHASE]: {
        benefit: [
          '//res.tutoring.co.kr/res/images/evt/202011/allforu/purchase_txt_allforu_w360.png',
          '//res.tutoring.co.kr/res/images/evt/202011/allforu/purchase_txt_allforu_w768.png',
        ],
      },
      [PLAN_CODE.ALPHA_PLAN]: {
        banner: {
          img: '//res.tutoring.co.kr/res/images/evt/202011/single/purchase_bnr_single_360w.png',
          background: '#ffffff',
        },
      },
      [PLAN_CODE.TUTORING_PLAY]: {
        benefit: [
          '//res.tutoring.co.kr/res/images/evt/202103/play/purchase_txt_play_w360.png?date=202103',
          '//res.tutoring.co.kr/res/images/evt/202103/play/purchase_txt_play_w768.png?date=202103',
        ],
        noFrame: true,
        message: '* 수강코스에 포함된 PLAY 회화토픽에 한해 무제한 이용 가능',
      },
      [PLAN_CODE.JICDING_ENGLISHTECH]: {
        banner: {
          img: '//res.tutoring.co.kr/res/images/evt/202011/tech/tech_purchase_img.jpg',
          background: '#ffffff',
        },
      },
      [PLAN_CODE.EVENT_2021FOCUSPACK]: {
        banner: {
          img: '//res.tutoring.co.kr/res/images/pay/bundle/2021buds/purchase_buds_img.jpg',
          background: '#000',
        },
      },
      [PLAN_CODE.MINI_BOOK_PLAN]: {
        background: {
          img: '//res.tutoring.co.kr/res/images/img/plans/ministudy/bnr_ministudy_360w.png',
          color: '#FFDB00',
        },
        benefit: ['//res.tutoring.co.kr/res/images/img/plans/ministudy/purchase_txt_ministudy_w360.png'],
        noFrame: true,
      },
    };
    this.topImage = topImage[this.code];
  },
};
