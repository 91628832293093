<template>
  <div class="box">
    <strong class="title">
      회원정보
      <i>*</i>
    </strong>
    <input class="info" type="text" :value="userName" disabled />
    <input class="info" type="text" :value="userEmail" disabled />
  </div>
</template>

<script lang="ts">
import { storage } from '@/tools';

export default {
  name: 'UserInfoBox',
  data() {
    return {
      userName: '',
      userEmail: '',
    };
  },
  mounted() {
    const userInfo = storage.session.get('loggedInUser');
    const userEmail = userInfo.basic.email || storage.persistent.get('ellaEmail');
    const userName = userInfo.basic.name || storage.persistent.get('name');
    this.userName = userName;
    this.userEmail = userEmail;
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: 10px;
  padding: 25px 20px 37px;
  background-color: #f9f9f9;
}
.title {
  display: block;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 17px;
  i {
    font-size: 0.7rem;
    font-style: normal;
    color: #ff5959;
    padding-left: 6px;
  }
}

.info {
  margin-top: 4px;
  background-color: #f2f2f2;
  font-size: 0.88rem;
  padding: 12.5px 13px;
  border-radius: 5px;
  border: 0;
  width: 100%;
  box-sizing: border-box;
}
</style>
