
import stringTool from '@/common/stringTool';
import numberTool from '@/common/numberTool';
import {
  /* CONST */
  BENEFIT_TYPE,
  PLAN_CODE,
  /* TYPE */
  TypeBenefitType,
} from '../modules/interface';
import { IOrderItem } from '../modules/OrderItem';
import { IOrderPay } from '../modules/OrderPay';

export default {
  name: 'OrderSheet',
  emits: ['goToPay'],
  props: {
    itemInfo: {
      type: Object as () => IOrderItem,
      required: true,
    },
    payInfo: {
      type: Object as () => IOrderPay,
      required: true,
    },
    additionalDc: {
      type: Object as () => { type: TypeBenefitType; amount: number },
      required: false,
    },
    showPlanInfo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isAgree: false as boolean,
    };
  },
  computed: {
    finalPrice() {
      return this.payInfo.salePrice - (this.additionalDc?.amount || 0);
    },
    lessonInfo() {
      let lessonInfo = '';
      if (this.itemInfo.period.months <= 0) {
        lessonInfo = stringTool.replaceVars('{{1}}일  | 총 {{2}}회', [
          this.itemInfo.period.days,
          this.itemInfo.lessonCnt,
        ]);
      } else if (this.itemInfo.period.months >= 3 && this.itemInfo.code === PLAN_CODE.BASIC) {
        lessonInfo = stringTool.replaceVars('주 {{1}}회 | 월 {{2}}회 X {{3}}개월 | 총 {{4}}회', [
          this.itemInfo.lessonCntPerWeek,
          this.itemInfo.lessonCnt / this.itemInfo.period.months,
          this.itemInfo.period.months,
          this.itemInfo.lessonCnt,
        ]);
      } else {
        lessonInfo = stringTool.replaceVars('주 {{1}}회 (월 {{4}}회) | {{2}}개월 | 총 {{3}}회', [
          this.itemInfo.lessonCntPerWeek,
          this.itemInfo.period.months,
          this.itemInfo.lessonCnt,
          this.itemInfo.lessonCnt / this.itemInfo.period.months,
        ]);
      }
      return lessonInfo;
    },
    discountPercent() {
      return this.showPlanInfo ? `${this.payInfo.discountedPercent}% 할인` : `-${this.payInfo.discountedPercent}%`;
    },
    discountMoney() {
      return stringTool.replaceVars('{{1}}원', [numberTool.format(Math.floor(this.payInfo.discountedAmount), null)]);
    },
    addtionalBenefit() {
      const amount = (this.additionalDc?.amount || 0).toLocaleString();
      if (this.additionalDc?.type === BENEFIT_TYPE.COUPON) {
        return stringTool.replaceVars('{{1}}원', [amount]);
      }
      return `${amount}P`;
    },
    bonusPointDesc() {
      return stringTool.replaceVars('구매액의 {{1}}%가 포인트로 적립됩니다.', [this.payInfo.earningPoints]);
    },
    monthlyPaidMoney() {
      return stringTool.replaceVars('월 {{1}}원 X {{2}}개월', [
        numberTool.format(this.payInfo.payPerMonth, null),
        this.itemInfo.period.months,
      ]);
    },
    totalPaidMoney() {
      return stringTool.replaceVars('총 {{1}}원', [numberTool.format(this.finalPrice, null)]);
    },
  },
  watch: {
    isAgree() {
      this.updateAgree();
    },
    payInfo() {
      if (this.isAgree !== this.payInfo.agree) {
        this.updateAgree();
      }
    },
  },
  methods: {
    updateAgree() {
      console.log('updateAgree!!!');
      // eslint-disable-next-line vue/no-mutating-props
      this.payInfo.agree = this.isAgree;
    },
    goToPay() {
      this.$emit('goToPay', this.finalPrice);
    },
    showTerm() {
      window.open('https://tutoring.co.kr/home/help/Z', '', '');
    },
  },
};
