
import { storage } from '@/tools';

export default {
  name: 'UserInfoBox',
  data() {
    return {
      userName: '',
      userEmail: '',
    };
  },
  mounted() {
    const userInfo = storage.session.get('loggedInUser');
    const userEmail = userInfo.basic.email || storage.persistent.get('ellaEmail');
    const userName = userInfo.basic.name || storage.persistent.get('name');
    this.userName = userName;
    this.userEmail = userEmail;
  },
};
