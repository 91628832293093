/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
export default {
  replaceVarsToArray(str) {
    const arr = str.split(/{{(0-9)+}}/i);
    return arr;
  },

  replaceVars(str, newStrArr) {
    for (let i = 0; i < newStrArr.length; i++) {
      str = this.replaceAll(str, `{{${i + 1}}}`, newStrArr[i]);
    }

    return str;
  },

  replaceAll(str, from, to, isCaseInsensitive) {
    if (!str) {
      return str;
    }
    from = from.replace(/[\\-\\[\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\^\\$\\|]/g, '\\$&'); // 정규식 특수문자 escape 처리
    const regexp = new RegExp(from, isCaseInsensitive ? 'gi' : 'g');
    return str.replace(regexp, to);
  },

  nl2br(str) {
    return str ? str.replace(/\n/g, '<br />') : str;
  },

  hashCode(str) {
    let hash = 0;
    let i = 0;
    const len = str.length;
    while (i < len) {
      hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
    }

    return hash;
  },

  copyToClipboard(val) {
    const t = document.createElement('textarea');
    document.body.appendChild(t);
    t.value = val;
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
  },

  numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
};
