import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cd95b06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StandardPlan = _resolveComponent("StandardPlan")!
  const _component_ComplexPlan = _resolveComponent("ComplexPlan")!
  const _component_SubscribePlan = _resolveComponent("SubscribePlan")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.isStandardPlan)
      ? (_openBlock(), _createBlock(_component_StandardPlan, {
          key: 0,
          orderInfo: $data.orderInfo,
          planOptions: $data.planOptions,
          onChangePlan: $options.changePlan,
          onShowAlert: $options.showAlert,
          onSetIsLoading: $options.setIsLoading
        }, null, 8 /* PROPS */, ["orderInfo", "planOptions", "onChangePlan", "onShowAlert", "onSetIsLoading"]))
      : _createCommentVNode("v-if", true),
    ($options.isComplexPlan)
      ? (_openBlock(), _createBlock(_component_ComplexPlan, {
          key: 1,
          orderInfo: $data.orderInfo,
          planOptions: $data.planOptions,
          onChangePlan: $options.changePlan,
          onShowAlert: $options.showAlert,
          onSetIsLoading: $options.setIsLoading
        }, null, 8 /* PROPS */, ["orderInfo", "planOptions", "onChangePlan", "onShowAlert", "onSetIsLoading"]))
      : _createCommentVNode("v-if", true),
    ($options.isSubscribePlan)
      ? (_openBlock(), _createBlock(_component_SubscribePlan, {
          key: 2,
          orderInfo: $data.orderInfo,
          onShowAlert: $options.showAlert,
          onSetIsLoading: $options.setIsLoading
        }, null, 8 /* PROPS */, ["orderInfo", "onShowAlert", "onSetIsLoading"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" 수강권 안내 "),
    _createVNode(_component_Footer, { guideList: $data.planGuide }, null, 8 /* PROPS */, ["guideList"]),
    _createCommentVNode(" Alert 팝업 "),
    _createVNode(_component_Alert, {
      title: $data.alertTitle,
      msg: $data.alertMsg,
      onClose: $options.closeAlert
    }, null, 8 /* PROPS */, ["title", "msg", "onClose"]),
    _createCommentVNode(" 스피너 "),
    _createVNode(_component_Loading, { isLoading: $data.isLoading }, null, 8 /* PROPS */, ["isLoading"])
  ]))
}