import { render } from "./UserInfoBox.vue?vue&type=template&id=8c69496e&scoped=true"
import script from "./UserInfoBox.vue?vue&type=script&lang=ts"
export * from "./UserInfoBox.vue?vue&type=script&lang=ts"

import "./UserInfoBox.vue?vue&type=style&index=0&id=8c69496e&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-8c69496e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "8c69496e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8c69496e', script)) {
    api.reload('8c69496e', script)
  }
  
  module.hot.accept("./UserInfoBox.vue?vue&type=template&id=8c69496e&scoped=true", () => {
    api.rerender('8c69496e', render)
  })

}

script.__file = "src/page/order/sections/UserInfoBox.vue"

export default script