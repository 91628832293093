import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-523931db"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popup"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "btn-wrapper" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($data.isShow)
    ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        _createElementVNode("div", {
          class: "dimmer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (Boolean($props.title))
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "title",
                  innerHTML: $props.title
                }, null, 8 /* PROPS */, _hoisted_4))
              : _createCommentVNode("v-if", true),
            _createElementVNode("p", {
              class: "message",
              innerHTML: $props.msg
            }, null, 8 /* PROPS */, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.buttons, (btn, idx) => {
              return (_openBlock(), _createElementBlock("button", {
                class: "btn",
                key: idx,
                onClick: btn.handler
              }, _toDisplayString(btn.label), 9 /* TEXT, PROPS */, _hoisted_7))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}