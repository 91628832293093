import { storage } from '@/tools';
import {
  /* CONST */
  LANGUAGE,
  /* TYPE */
  TypeLanguage,
} from './interface';

export interface IOrderUser {
  key: string;
  name: string;
  email: string;
  country: string;
  lang: TypeLanguage;
  isKorean: boolean;
}

export default class OrderUser implements IOrderUser {
  private _key: string;

  private _name: string;

  private _email: string;

  private _country: string;

  private _lang: TypeLanguage;

  get key(): string {
    return this._key;
  }

  get name(): string {
    return this._name;
  }

  get email(): string {
    return this._email;
  }

  get country(): string {
    return this._country;
  }

  get lang(): TypeLanguage {
    return this._lang;
  }

  get isKorean(): boolean {
    return this._lang === LANGUAGE.KR || this._country === 'kr';
  }

  constructor(userInfo: any) {
    const email = storage.persistent.get('ellaEmail');
    const lang = storage.session.get('lang');

    this._key = userInfo.basic?.key || '';
    this._name = userInfo.basic?.name || '';
    this._email = email || '';
    this._country = userInfo.basic?.country || 'kr';
    this._lang = lang || 'kr';
  }
}
