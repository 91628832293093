export const ORDER_TYPE = {
  STANDARD: 0,
  SUBSCRIBE: 1,
  COMPLEX: 2,
} as const;

export const PLAN_CODE = {
  /**
   *  [ 튜터링 ]
   */
  ALLPASS: 'allpass',
  ALLPASS_NEW: 'allpass_new',
  ALLPASS_REPURCHASE: 'allpass_repurchase',
  ALPHA_PLAN: 'alpha', // 단과패스
  TUTORING_PLAY: 'play',
  MINI_BOOK_PLAN: '202107_mini',
  JICDING_ENGLISHTECH: 'renewaljicding',
  EVENT_2021FOCUSPACK: '202103_focuspack',
  /**
   *  [ 쨔요 ]
   */
  DAWATDA_PASS: 'dawatda_pass', // 다왓다
  REAL_BEGINNER_PLAN: 'event_17', // 찐초보
  /**
   *  [ 공통 ]
   *   - 글로벌 유저 (튜터링, 짜요, 장기, 단기 플랜 전부 다 해당)
   *   - 쨔요 패스
   */
  BASIC: 'cp',
} as const;

export const PAY_METHOD = {
  CARD: 'C',
  PHONE: 'P',
  VIRTUAL_ACCOUNT: 'DB',
  PAYMENTWALL: 'PW',
  SUBSCRIBE: 'SP',
} as const;

export const BENEFIT_OPTIONS = {
  NONE: 0,
  BOTH: 1,
  POINT: 2,
  COUPON: 3,
} as const;

export const BENEFIT_TYPE = {
  POINT: 'P',
  COUPON: 'C',
} as const;

export const DELIVERY_TYPE = {
  NONE: 0,
  S: 1,
  SP: 2,
  SD: 3,
} as const;

export const TUTOR_TYPE = {
  NATIVE: 'B',
  GLOBAL: 'C',
  CHINESE: 'L',
} as const;

export const TUTOR_LABEL = {
  [TUTOR_TYPE.NATIVE]: 'Native',
  [TUTOR_TYPE.GLOBAL]: 'Global',
  [TUTOR_TYPE.CHINESE]: 'Chinese',
  [TUTOR_TYPE.NATIVE + TUTOR_TYPE.CHINESE]: 'Native + Global',
} as const;

export const LANGUAGE = {
  KR: 'kr',
  EN: 'en',
  CN: 'cn',
  JP: 'jp',
} as const;

export const PAY_CHANNEL = {
  INAPP: 2,
  WEB: 4,
} as const;

export const PRODUCT_TYPE = {
  DEVICE: 1,
  PACKAGE: 2,
  POINT_MALL: 5,
  JICDING: 8,
  DAWATDA: 25,
  PPUNG: 28,
  MINI_BOOK: 27,
} as const;

export type TypeOrderType = typeof ORDER_TYPE[keyof typeof ORDER_TYPE];
export type TypePlanCode = typeof PLAN_CODE[keyof typeof PLAN_CODE];
export type TypePayMethod = typeof PAY_METHOD[keyof typeof PAY_METHOD];
export type TypeBenefitOptions = typeof BENEFIT_OPTIONS[keyof typeof BENEFIT_OPTIONS];
export type TypeBenefitType = typeof BENEFIT_TYPE[keyof typeof BENEFIT_TYPE];
export type TypeDeliveryType = typeof DELIVERY_TYPE[keyof typeof DELIVERY_TYPE];
export type TypeTutorType = typeof TUTOR_TYPE[keyof typeof TUTOR_TYPE];
export type TypeTutorLabel = typeof TUTOR_LABEL[keyof typeof TUTOR_LABEL];
export type TypeLanguage = typeof LANGUAGE[keyof typeof LANGUAGE];
export type TypeProductType = typeof PRODUCT_TYPE[keyof typeof PRODUCT_TYPE];

export type TypeOption = {
  idx: number;
  code: string;
  title: string;
  image: string;
};

export type TypeCoupon = {
  dcIdx: number;
  dcMoneyGlobal: number;
  dcsIdx: number;
  discountMoney: number;
  discountMoneyForGlobal: number;
  isRateCoupon: boolean;
  label: {
    title: string;
  };
  title: string;
  maxDiscountMoney: number;
  minMoneyOfCouponAvailable: number;
  selectedPlanDiscountMoney: number;
  selectedType: string;
};

export type TypeGuide = {
  title: string;
  contents: Array<{
    text: string;
  }>;
};

export type TypeDelivery = {
  recipient: string;
  mobile: string;
  tel: string;
  zipCode: string;
  address: string;
  message: string;
};

export type TypeSubscribeCard = {
  cardName: string;
  cardNumber: string;
};

export type TypeRegisterCard = {
  cardNumber: [string, string, string, string];
  expirationYear: string;
  expirationMonth: string;
  pwd2Digit: string;
  birth: string;
};

export type TypePayment = {
  mtIdx: string;
  code: TypePlanCode;
  titleForPG: string;
  payMethod: TypePayMethod;
  totalPaidMoney: number;
  months: number;
};

export type TypePlan = {
  type: TypePlanCode;
  title: string;
  titleForPG: string;
  lessonPerWeek: number;
  money: string;
  day: number;
  origin_money: string;
  isDollar: 0 | 1;
  isSubscribePlan: boolean;
  minute: number;
  month: number;
  moneyPerMonth: number;
  originMoneyPerMonth: number;
  month_cnt: number;
  discount: string;
  cpcIdx: number;
  lesson_cnt: number;
  course_idx: string | null;
  cp_idx: number;
  cpg_idx: number;
  t_type: TypeTutorType;
  is_group: boolean;
  bonusPointPercentage: number;
  options: Array<TypeOption>;
  additionalInfo: {
    needAddress: TypeDeliveryType;
    additionalBenefitType: TypeBenefitOptions;
  };
  pointInfo: {
    repurchaseUsePointPercentage: string;
    useMaxPoint: number;
    usePointPercentage: string;
    useRepurchaseMaxPoint: number;
  };
};
