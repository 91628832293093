
import { IOrderPay } from '../modules/OrderPay';
import { Alert } from '../components';

export default {
  name: 'PaySubscribe',
  components: {
    Alert,
  },
  props: {
    payInfo: {
      type: Object as () => IOrderPay,
      required: true,
    },
  },
  data() {
    return {
      card: {
        carName: '',
        carNumber: '',
      },
      newCard: {
        cardNumber: ['', '', '', ''],
        expirationMonth: '',
        expirationYear: '',
        pwd2Digit: '',
        birth: '',
      },
      alertTitle: '',
      alertMsg: '',
      alertBtns: null,
    };
  },
  methods: {
    confirmRegisterCard() {
      this.alertTitle = '카드 정보를 변경하시겠어요?';
      this.alertMsg =
        '카드 신규 등록 시, 기존에 결제되던 정기 결제 정보도 해당 카드로 업데이트됩니다.\n\n새롭게 등록하시겠습니까?';
      this.alertBtns = [
        { label: '아니요', handler: () => this.closeAlert() },
        {
          label: '신규등록',
          handler: () => {
            this.card.cardName = '';
            this.card.cardNumber = '';
            this.closeAlert();
            this.$nextTick(() => this.$refs.registerCard1.focus());
          },
        },
      ];
    },
    registerCard() {
      // eslint-disable-next-line vue/no-mutating-props
      this.payInfo.card = this.newCard;
    },
    checkInput(e) {
      const {
        value,
        maxLength,
        id,
        dataset: { idx },
      } = e.target;
      if (value.length >= maxLength) {
        const inputs = Object.keys(this.$refs).filter((ref) => ref.includes('registerCard'));
        const newVal = value.slice(0, maxLength);
        const idxInt = Number(idx);
        if (idxInt < 5) {
          this.cardNumber[idxInt - 1] = newVal;
        } else {
          this[id] = newVal;
        }
        if (idxInt < inputs.length) {
          this.$refs[`registerCard${idxInt + 1}`].focus();
        } else {
          this.$refs[`registerCard${idx}`].blur();
        }
        this.registerCard();
      }
    },
    async openPolicy(type: number) {
      const { title, content } = await this.payInfo.getSubscribePolicy(type);
      this.alertTitle = title;
      this.alertMsg = content;
      this.alertBtns = [
        {
          label: '닫기',
          handler: () => this.closeAlert(),
        },
      ];
    },
    closeAlert() {
      this.alertTitle = '';
      this.alertMsg = '';
      this.alertBtns = null;
    },
  },
  async mounted() {
    const card = await this.payInfo.getSubscribeCardInfo();
    this.card = card;
  },
};
