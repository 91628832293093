import date from '@/common/date';
import { TypePlan, TypePlanCode, TypeTutorType, TypeOption } from './interface';

export interface IOrderItem {
  code: TypePlanCode;
  name: string;
  nameForPG: string;
  cpIdx: number;
  cpcIdx: number;
  cpgIdx: number;
  courseIdxs: string;
  lessonCnt: number;
  lessonCntPerWeek: number;
  lessonMinutes: number;
  isGroup: boolean;
  tutorType: TypeTutorType;
  options: Array<TypeOption>;
  period: {
    days: number;
    months: number;
    frDt: string;
    toDt: string;
  };
}

export default class OrderItem implements IOrderItem {
  private _code: TypePlanCode;

  private _name: string;

  private _nameForPG: string;

  private _cpIdx: number;

  private _cpcIdx: number;

  private _cpgIdx: number;

  private _courseIdxs: string;

  private _lessonCnt: number;

  private _lessonCntPerWeek: number;

  private _lessonMinutes: number;

  private _isGroup: boolean;

  private _tutorType: TypeTutorType;

  private _options: Array<TypeOption>;

  private _period: {
    days: number;
    months: number;
    frDt: string;
    toDt: string;
  };

  get code(): TypePlanCode {
    return this._code;
  }

  get name(): string {
    return this._name;
  }

  get nameForPG(): string {
    return this._nameForPG;
  }

  get cpIdx(): number {
    return this._cpIdx;
  }

  get cpcIdx(): number {
    return this._cpcIdx;
  }

  get cpgIdx(): number {
    return this._cpgIdx;
  }

  get courseIdxs(): string {
    return this._courseIdxs;
  }

  get lessonCnt(): number {
    return this._lessonCnt;
  }

  get lessonCntPerWeek(): number {
    return this._lessonCntPerWeek;
  }

  get lessonMinutes(): number {
    return this._lessonMinutes;
  }

  get isGroup(): boolean {
    return this._isGroup;
  }

  get tutorType(): TypeTutorType {
    return this._tutorType;
  }

  get options(): Array<TypeOption> {
    return this._options;
  }

  set options(options: Array<TypeOption>) {
    this._options = options;
  }

  get period(): {
    days: number;
    months: number;
    frDt: string;
    toDt: string;
  } {
    return this._period;
  }

  constructor(planInfo: TypePlan, userInfo: any) {
    const {
      type: code,
      title: name,
      titleForPG: nameForPG,
      cp_idx: cpIdx,
      cpcIdx,
      cpg_idx: cpgIdx,
      course_idx: courseIdxs,
      lesson_cnt: lessonCnt,
      lessonPerWeek: lessonCntPerWeek,
      minute: lessonMinutes,
      is_group: isGroup,
      t_type: tutorType,
      options,
      day: days,
      month_cnt: months,
    } = planInfo;
    const maxToDt = userInfo.goods?.charge_plan?.to_dt_by_ver.ver2.split(' ')[0];
    const frDt = maxToDt ? date.formatYMD(date.addDays(maxToDt, 1)) : date.formatYMD(new Date());
    const toDt = days
      ? date.formatYMD(date.addDays(frDt, days - 1))
      : date.formatYMD(date.addDays(date.addMonths(frDt, months), -1));

    this._code = code;
    this._name = name;
    this._nameForPG = nameForPG;
    this._cpIdx = cpIdx;
    this._cpcIdx = cpcIdx;
    this._cpgIdx = cpgIdx;
    this._courseIdxs = courseIdxs || '';
    this._lessonCnt = lessonCnt;
    this._lessonCntPerWeek = lessonCntPerWeek;
    this._lessonMinutes = lessonMinutes;
    this._isGroup = isGroup;
    this._tutorType = tutorType;
    this._options = options;
    this._period = { days, months, frDt, toDt };
  }
}
