<template>
  <div class="loading" v-if="isLoading">
    <div class="spinner" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'Loading',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    isLoading: {
      immediate: true,
      handler() {
        document.body.style.overflow = this.isLoading ? 'hidden' : '';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.3);
  z-index: 9999;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -55px;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  border-top: 4px solid #ededed;
  border-right: 4px solid #ededed;
  border-bottom: 4px solid #ededed;
  border-left: 4px solid #34bcff;
  border-radius: 50%;
  text-indent: -9999em;
  animation: spin 0.8s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
