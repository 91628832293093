<template>
  <ul class="list" v-if="payInfo.isSubscribe">
    <li class="item">
      <input type="radio" name="payMethod" id="pay" :value="PAY_METHOD.SUBSCRIBE" v-model="selected" />
      <label class="label" for="pay">
        <strong class="title">월 정기 구독</strong>
        <span class="noti">- 신용/체크카드</span>
        <span class="noti">- 매월 첫구매한 날짜에 자동으로 결제</span>
        <span class="noti">- 3개월 이상 정기구독 시, 자동 할인 혜택 적용</span>
      </label>
    </li>
  </ul>
  <ul class="list" v-else>
    <li class="item">
      <input type="radio" name="payMethod" id="pay1" :value="PAY_METHOD.CARD" v-model="selected" />
      <label class="label" for="pay1">
        <strong class="title">신용/체크카드</strong>
        <span class="noti">- 최대 무이자 12개월 지원(카드, 상품별 조건 상이)</span>
        <span class="noti">- 체크 카드,법인 카드는 할부결제 불가</span>
        <span class="noti" v-if="payInfo.earningPoints">- {{ bonusPointDesc }}</span>
      </label>
    </li>
    <li class="item">
      <input type="radio" name="payMethod" id="pay2" :value="PAY_METHOD.VIRTUAL_ACCOUNT" v-model="selected" />
      <label class="label" for="pay2">
        <strong class="title">가상계좌(무통장 입금)</strong>
        <span class="noti">- 결제 희망 은행 선택 시, 가상계좌 번호 발행</span>
        <span class="noti">- 주문 완료 후 48시간 내 가상계좌로 입금 즉시 수강권 지급</span>
        <span class="noti" v-if="payInfo.earningPoints">- {{ bonusPointDesc }}</span>
      </label>
    </li>
    <li class="item">
      <input type="radio" name="payMethod" id="pay3" :value="PAY_METHOD.PHONE" v-model="selected" />
      <label class="label" for="pay3">
        <strong class="title">휴대폰 결제</strong>
        <span class="noti">- 휴대폰 결제 취소는 결제 당월에만 가능</span>
        <span class="noti">- 휴대폰 결제 한도 최대 월 60만 원</span>
        <span class="noti" v-if="payInfo.earningPoints">- {{ bonusPointDesc }}</span>
      </label>
    </li>
  </ul>
</template>

<script lang="ts">
import stringTool from '@/common/stringTool';
import { PAY_METHOD } from '../modules/interface';
import { IOrderPay } from '../modules/OrderPay';

export default {
  name: 'PayMethod',
  props: {
    payInfo: {
      type: Object as () => IOrderPay,
      required: true,
    },
  },
  data() {
    return {
      PAY_METHOD,
      selected: this.payInfo.isSubscribe ? PAY_METHOD.SUBSCRIBE : PAY_METHOD.CARD,
    };
  },
  computed: {
    bonusPointDesc() {
      return stringTool.replaceVars('전체 구매액의 {{1}}% 포인트로 적립', [this.payInfo.earningPoints]);
    },
  },
  watch: {
    selected() {
      this.updatePayMethod();
    },
    'payInfo.method': {
      handler() {
        if (!this.payInfo.isSubscribe) {
          this.updatePayMethod();
        }
      },
    },
  },
  methods: {
    updatePayMethod() {
      // eslint-disable-next-line vue/no-mutating-props
      this.payInfo.method = this.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  margin: 14px 20px 64px;
}

.item {
  position: relative;
  margin-bottom: 30px;
  padding-left: 42px;
  input[type='radio'] {
    display: none;
    & + .label::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 28px;
      height: 28px;
      background-image: url('//res.tutoring.co.kr/res/images/spr/ico_comm_181220.png');
      background-repeat: no-repeat;
      background-size: 200px 160px;
      background-position: -170px 0;
    }
    &:checked + .label {
      .title {
        color: #00abff;
      }
      &::before {
        background-position: -170px -30px;
      }
    }
  }
}

.title {
  display: block;
  font-size: 1.145rem;
  line-height: 1.4;
  font-weight: normal;
}

.noti {
  display: block;
  font-size: 0.786rem;
  margin-bottom: 1px;
  &.important {
    color: #ff3434;
  }
}
</style>
