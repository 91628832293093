
import date from '@/common/date';
import { DELIVERY_TYPE } from '../modules/interface';
import { IOrderDelivery } from '../modules/OrderDelivery';
import { PostCodeSearch, Alert } from '../components';

export default {
  name: 'DeliveryForm',
  components: {
    PostCodeSearch,
    Alert,
  },
  emits: ['updateDeliveryInfo'],
  props: {
    deliveryInfo: {
      type: Object as () => IOrderDelivery,
      required: true,
    },
  },
  data() {
    return {
      recipient: '',
      mobile: '',
      tel: '',
      zipCode: '',
      address1: '',
      address2: '',
      message: '',
      phoneCode: '',
      phoneAuth: false,
      phoneCodeRemainTime: '',
      phoneAuthTimer: null,
      alertTitle: '',
      alertMsg: '',
      isPostSearchShow: false,
    };
  },
  computed: {
    isShowMobile() {
      return this.deliveryInfo.type === DELIVERY_TYPE.S || this.deliveryInfo.type === DELIVERY_TYPE.SP;
    },
    isShowTel() {
      return this.deliveryInfo.type === DELIVERY_TYPE.S;
    },
    isShowAddress() {
      return this.deliveryInfo.type === DELIVERY_TYPE.S || this.deliveryInfo.type === DELIVERY_TYPE.SD;
    },
  },
  watch: {
    deliveryInfo() {
      this.updateDeliveryInfo();
    },
  },
  methods: {
    updateDeliveryInfo() {
      // eslint-disable-next-line vue/no-mutating-props
      if (this.recipient) this.deliveryInfo.recipient = this.recipient;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.phoneAuth && this.mobile) this.deliveryInfo.mobile = this.mobile;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.tel) this.deliveryInfo.tel = this.tel;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.zipCode) this.deliveryInfo.zipCode = this.zipCode;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.address1) this.deliveryInfo.address1 = this.address1;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.address2) this.deliveryInfo.address2 = this.address2;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.recipient) this.deliveryInfo.recipient = this.recipient;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.message) this.deliveryInfo.message = this.message;
    },
    setPhoneAuthTimer(expireDt: string) {
      const expireTime = date.stringToDate(expireDt);
      const now = new Date().getTime();
      let diff = expireTime.valueOf() - now.valueOf();

      const phoneAuthFunc = () => {
        diff -= 1000;
        const secs = diff <= 0 ? 0 : diff / 1000;
        const min = Math.floor((secs / 60) % 60);
        this.phoneCodeRemainTime = min > 0 ? `${min}분 ${Math.floor(secs % 60)}초` : `${Math.floor(secs % 60)}초`;
        if (diff <= 0) {
          this.clearPhoneAuthTimer();
        }
      };
      phoneAuthFunc();
      this.phoneAuthTimer = setInterval(phoneAuthFunc, 1000);
    },
    clearPhoneAuthTimer() {
      if (this.phoneAuthTimer) {
        clearInterval(this.phoneAuthTimer);
        this.phoneCodeRemainTime = '';
      }
    },
    async requestPhoneCode() {
      if (this.mobile) {
        const { success, data } = await this.deliveryInfo.requestMobileCheck(this.mobile);
        this.clearPhoneAuthTimer();
        if (success) {
          this.alertMsg = '휴대폰으로 발송된 인증번호 4자리를 입력해주세요.';
          this.phoneCode = '';
          this.setPhoneAuthTimer(data);
        } else {
          this.alertTitle = '인증 번호 발송 실패';
          this.alertMsg = '인증 번호 발송이 실패했습니다.\n휴대폰 번호를 다시 한번 확인해주세요.';
        }
      } else {
        this.alertMsg = '배송받는 분 연락처를 입력해주세요';
      }
    },
    async requestConfirmPhone() {
      const { success, data: msg } = await this.deliveryInfo.confirmMobileCheck(this.mobile, this.phoneCode);
      this.alertMsg = msg;
      if (success) {
        this.clearPhoneAuthTimer();
        this.updateDeliveryInfo();
        this.phoneAuth = true;
      }
    },
    setAddress(data: { zipCodeData: string; addressData: string }) {
      const { zipCodeData: zipCode, addressData: address1 } = data;
      this.zipCode = zipCode;
      this.address1 = address1;
      this.isPostSearchShow = false;
      this.updateDeliveryInfo();
    },
    closeAlert() {
      this.alertTitle = '';
      this.alertMsg = '';
    },
  },
};
