
/* https://postcode.map.daum.net/guide#attributes */
import { VueDaumPostcode, VueDaumPostcodeCompleteResult } from 'vue-daum-postcode';

const ADDRESS_TYPE = {
  ROAD: 'R',
  JIBUN: 'J',
} as const;

export default {
  name: 'PostCodeSearch',
  components: {
    VueDaumPostcode,
  },
  methods: {
    onComplete(result: VueDaumPostcodeCompleteResult) {
      const {
        addressType,
        address,
        postcode,
        zonecode,
        bname, // 법정동, 법정리 이름
        buildingName,
      } = result;
      let extraAddress = '';
      if (addressType === ADDRESS_TYPE.ROAD) {
        if (bname) {
          extraAddress += bname;
        }
        if (buildingName) {
          extraAddress += extraAddress ? `, ${buildingName}` : buildingName;
        }
      }
      const zipCodeData = postcode || zonecode;
      const addressData = `${address} ${extraAddress ? `(${extraAddress})` : ''}`;
      this.$emit('complete', { zipCodeData, addressData });
    },
  },
};
