<template>
  <aside class="popup" v-if="isShow">
    <div class="dimmer" @click="$emit('close')" />
    <div class="inner">
      <div class="content">
        <p class="title" v-if="Boolean(title)" v-html="title" />
        <p class="message" v-html="msg" />
      </div>
      <div class="btn-wrapper">
        <button class="btn" v-for="(btn, idx) in buttons" :key="idx" @click="btn.handler">
          {{ btn.label }}
        </button>
      </div>
    </div>
  </aside>
</template>

<script lang="ts">
export default {
  name: 'Alert',
  props: {
    title: {
      type: String,
      required: false,
    },
    msg: {
      type: String,
      required: true,
    },
    btns: {
      type: Object as () => Array<{ label: string; handler: () => void }>,
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
      buttons: [],
    };
  },
  watch: {
    msg: {
      immediate: true,
      handler() {
        this.setPopupShow();
      },
    },
    btns: {
      immediate: true,
      handler() {
        this.setButtons();
      },
    },
  },
  methods: {
    setButtons() {
      if (!this.btns) {
        this.buttons = [{ label: '확인', handler: () => this.$emit('close') }];
      } else {
        this.buttons = this.btns;
      }
    },
    setPopupShow() {
      const isShow = Boolean(this.msg);
      this.isShow = isShow;
      document.body.style.overflow = isShow ? 'hidden' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
}

.dimmer {
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  opacity: 0.3;
}

.inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 50%;
  max-width: 600px;
  margin: 0 auto;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  max-height: calc(100vh - 300px);
  padding: 22px 24px 24px;
  text-align: center;
  overflow-y: scroll;
}

.title {
  margin-bottom: 10px;
  font-size: 1.13rem;
  letter-spacing: -0.5px;
  font-weight: bold;
}

.message {
  font-size: 1rem;
  line-height: 1.47;
  white-space: pre-line;
}

.btn-wrapper {
  height: 55px;
  display: flex;
  border-top: 1px solid #efefef;
}

.btn {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  color: #333;
  letter-spacing: -0.7px;
  & + .btn {
    border-left: 1px solid #efefef;
  }
}

@media (max-width: 768px) {
  .inner {
    width: 80%;
  }
}
</style>
