import { render } from "./RepurchaseBenefit.vue?vue&type=template&id=052e9d5a&scoped=true"
import script from "./RepurchaseBenefit.vue?vue&type=script&lang=ts"
export * from "./RepurchaseBenefit.vue?vue&type=script&lang=ts"

import "./RepurchaseBenefit.vue?vue&type=style&index=0&id=052e9d5a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-052e9d5a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "052e9d5a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('052e9d5a', script)) {
    api.reload('052e9d5a', script)
  }
  
  module.hot.accept("./RepurchaseBenefit.vue?vue&type=template&id=052e9d5a&scoped=true", () => {
    api.rerender('052e9d5a', render)
  })

}

script.__file = "src/page/order/sections/RepurchaseBenefit.vue"

export default script