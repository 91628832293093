
import stringTool from '@/common/stringTool';
import numberTool from '@/common/numberTool';
import { IOrderBenefit } from '../modules/OrderBenefit';

export default {
  name: 'RepurchaseBenefit',
  props: {
    benefitInfo: {
      type: Object as () => IOrderBenefit,
      required: true,
    },
    useBenefit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isRepurhchaseBenefitTarget: false as boolean,
      checked: this.useBenefit as boolean,
    };
  },
  computed: {
    maxPercent() {
      return this.useBenefit ? this.benefitInfo.maxPercentRepurchase : this.benefitInfo.maxPercent;
    },
    maxAmount() {
      return this.useBenefit ? this.benefitInfo.maxAmountRepurchase : this.benefitInfo.maxAmount;
    },
    desc() {
      return stringTool.replaceVars('* 결제액의 {{1}}% (최대 {{2}}p)까지 포인트 사용 가능', [
        this.maxPercent,
        numberTool.format(this.maxAmount, null),
      ]);
    },
  },
  watch: {
    checked() {
      this.$emit('update:useBenefit', this.checked);
    },
  },
  async mounted() {
    const isRepurhchaseBenefitTarget = await this.benefitInfo.getIsRepurchaseBenefitTarget();
    this.isRepurhchaseBenefitTarget = isRepurhchaseBenefitTarget;
  },
};
