<template>
  <!-- 재구매 혜택 -->
  <RepurchaseBenefit
    :benefitInfo="orderInfo.benefit"
    :useBenefit="useRepurchaseBenefit"
    @update:useBenefit="(isUse) => (useRepurchaseBenefit = isUse)"
  />
  <!-- 상단 이미지 -->
  <TopImage :code="orderInfo.item.code" />
  <!-- 옵션 선택 -->
  <PlanOptions :itemInfo="orderInfo.item" :planOptions="planOptions" @changePlan="changePlan" />
  <ComplexOptions :itemInfo="orderInfo.item" />
  <!-- 주문 내역 -->
  <section class="section">
    <h4 class="section__title">주문 내역</h4>
    <OrderSheet :itemInfo="orderInfo.item" :payInfo="orderInfo.pay" :additionalDc="additionalDc" :showPlanInfo="true" />
  </section>
  <!-- 정보 입력 -->
  <section class="section">
    <h4 class="section__title">정보 입력</h4>
    <UserInfoBox />
    <DeliveryForm :deliveryInfo="orderInfo.delivery" />
  </section>
  <!-- 할인 금액 -->
  <section class="section" v-if="isShowAdditionalBenefit">
    <h4 class="section__title">할인 금액</h4>
    <AdditionalBenefit
      :benefitInfo="orderInfo.benefit"
      :useRepurchaseBenefit="useRepurchaseBenefit"
      :additionalDc="additionalDc"
      @update:additionalDc="(newAddtionalDc) => (additionalDc = newAddtionalDc)"
    />
  </section>
  <!-- 결제 수단 -->
  <section class="section">
    <h4 class="section__title">결제수단</h4>
    <PayMethod :payInfo="orderInfo.pay" />
  </section>
  <!-- 주문 내역 확인 -->
  <section class="section">
    <h4 class="section__title">주문 내역 확인</h4>
    <OrderSheet
      :itemInfo="orderInfo.item"
      :payInfo="orderInfo.pay"
      :additionalDc="additionalDc"
      :showPlanInfo="false"
      @goToPay="goToPay"
    />
  </section>
</template>

<script lang="ts">
import {
  /* CONST */
  BENEFIT_TYPE,
  BENEFIT_OPTIONS,
  /* TYPE */
  TypePlan,
  TypeBenefitType,
  TypePayment,
} from '../modules/interface';
import { IOrder } from '../modules/Order';
import {
  RepurchaseBenefit,
  TopImage,
  PlanOptions,
  ComplexOptions,
  OrderSheet,
  UserInfoBox,
  DeliveryForm,
  AdditionalBenefit,
  PayMethod,
} from '../sections';
import PaymentKorean from '../../payment/modules/PaymentKorean';

export default {
  name: 'ComplexPlan',
  components: {
    RepurchaseBenefit,
    TopImage,
    PlanOptions,
    ComplexOptions,
    OrderSheet,
    UserInfoBox,
    DeliveryForm,
    AdditionalBenefit,
    PayMethod,
  },
  emits: ['changePlan', 'showAlert', 'setIsLoading'],
  props: {
    orderInfo: {
      type: Object as () => IOrder,
      required: true,
    },
    planOptions: {
      type: Object as () => Array<TypePlan>,
      required: true,
    },
  },
  data() {
    return {
      useRepurchaseBenefit: true as boolean,
      additionalDc: {
        type: BENEFIT_TYPE.POINT,
        amount: 0,
      } as {
        type: TypeBenefitType;
        amount: number;
        couponIdx?: number;
      },
    };
  },
  computed: {
    isShowAdditionalBenefit() {
      return this.orderInfo.benefit.option !== BENEFIT_OPTIONS.NONE;
    },
  },
  methods: {
    changePlan(plan: TypePlan) {
      this.$emit('changePlan', plan);
    },
    checkPay() {
      if (!this.orderInfo.delivery.checkRecipient()) {
        this.$emit('showAlert', { msg: '배송받는 분 성함을 입력해주세요' });
        return false;
      }
      if (!this.orderInfo.delivery.checkMobile()) {
        this.$emit('showAlert', { msg: '휴대폰 번호를 인증해주세요.' });
        return false;
      }
      if (!this.orderInfo.delivery.checkTel()) {
        this.$emit('showAlert', { msg: '연락처를 확인해주세요.' });
        return false;
      }
      if (!this.orderInfo.delivery.checkAddress()) {
        this.$emit('showAlert', { msg: '배송받는 분 주소를 입력해주세요' });
        return false;
      }
      if (!this.orderInfo.pay.checkAgree()) {
        this.$emit('showAlert', { msg: '결제 동의를 확인해주세요' });
        return false;
      }
      return true;
    },
    async goToPay(finalPrice: number) {
      if (!this.checkPay()) return;
      this.$emit('setIsLoading', true);
      const resSubmitPay = await this.orderInfo.submitPay(finalPrice, this.additionalDc);
      if (!resSubmitPay.success) {
        const { title, msg } = resSubmitPay.data as { title: string; msg: string };
        this.$emit('setIsLoading', false);
        this.$emit('showAlert', { title, msg });
        return;
      }
      const mtIdx = resSubmitPay.data as string;
      const resSubmitDelivery = await this.orderInfo.submitDelivery(mtIdx);
      if (!resSubmitDelivery.success) {
        const msg = resSubmitDelivery.data as string;
        this.$emit('setIsLoading', false);
        this.$emit('showAlert', { msg });
        return;
      }
      this.orderInfo.saveSessionDelivery();
      this.openPaymentGateway(finalPrice, mtIdx);
      this.$emit('setIsLoading', false);
    },
    async openPaymentGateway(finalPrice: number, mtIdx: string) {
      const {
        code,
        nameForPG: titleForPG,
        period: { months },
      } = this.orderInfo.item;
      const { method: payMethod } = this.orderInfo.pay;
      const paymentInfo = {
        mtIdx,
        code,
        titleForPG,
        payMethod,
        totalPaidMoney: finalPrice,
        months,
      } as TypePayment;
      const payment = new PaymentKorean(paymentInfo, false);
      await payment.init();
      await payment.loadPgForm();
      await payment.fillPgForm();
      payment.submitPgForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin-top: 28px;
  &__title {
    display: block;
    padding: 0 20px;
    font-size: 24px;
    line-height: 36px;
  }
}
</style>
