<template>
  <section class="repurchase-benefit" v-if="isRepurhchaseBenefitTarget">
    <input class="input" type="checkbox" id="repurchaseBenefit" v-model="checked" />
    <label class="label" for="repurchaseBenefit">재구매 혜택 사용</label>
    <p class="noti">
      {{ desc }}
    </p>
  </section>
</template>

<script lang="ts">
import stringTool from '@/common/stringTool';
import numberTool from '@/common/numberTool';
import { IOrderBenefit } from '../modules/OrderBenefit';

export default {
  name: 'RepurchaseBenefit',
  props: {
    benefitInfo: {
      type: Object as () => IOrderBenefit,
      required: true,
    },
    useBenefit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isRepurhchaseBenefitTarget: false as boolean,
      checked: this.useBenefit as boolean,
    };
  },
  computed: {
    maxPercent() {
      return this.useBenefit ? this.benefitInfo.maxPercentRepurchase : this.benefitInfo.maxPercent;
    },
    maxAmount() {
      return this.useBenefit ? this.benefitInfo.maxAmountRepurchase : this.benefitInfo.maxAmount;
    },
    desc() {
      return stringTool.replaceVars('* 결제액의 {{1}}% (최대 {{2}}p)까지 포인트 사용 가능', [
        this.maxPercent,
        numberTool.format(this.maxAmount, null),
      ]);
    },
  },
  watch: {
    checked() {
      this.$emit('update:useBenefit', this.checked);
    },
  },
  async mounted() {
    const isRepurhchaseBenefitTarget = await this.benefitInfo.getIsRepurchaseBenefitTarget();
    this.isRepurhchaseBenefitTarget = isRepurhchaseBenefitTarget;
  },
};
</script>

<style lang="scss" scoped>
.repurchase-benefit {
  padding: 20px;
}

.label {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 48px;
  font-weight: 700;
  font-size: 1.145rem;
  border-radius: 11px;
  border: 1px solid #e7e4e4;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  background-color: #fff;
  &::before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    top: 17px;
    left: 15px;
    background-image: url('//res.tutoring.co.kr/res/images/spr/ico_comm_181220.png');
    background-repeat: no-repeat;
    background-size: 200px 160px;
    background-position: -68px 0;
  }
}

.input {
  display: none;
  &:checked + .label {
    color: #0099ff;
    &::before {
      background-position: -68px -28px;
    }
  }
}

.noti {
  margin-top: 8px;
  font-size: 0.714rem;
  line-height: 1.6;
  color: #ff5959;
}
</style>
