
export default {
  name: 'Loading',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    isLoading: {
      immediate: true,
      handler() {
        document.body.style.overflow = this.isLoading ? 'hidden' : '';
      },
    },
  },
};
