<template>
  <div class="radio" v-if="showPointOption" @click="updateSelectedType(BENEFIT_TYPE.POINT)">
    <input
      class="input-radio"
      type="radio"
      id="usePoint"
      :value="BENEFIT_TYPE.POINT"
      :checked="selected === BENEFIT_TYPE.POINT"
    />
    <label class="label" for="usePoint">
      <strong class="title">포인트</strong>
    </label>
    <ul class="contents">
      <li class="item" :style="{ 'margin-top': '12px' }">
        <span class="title">보유 포인트</span>
        <div class="content">
          <strong>{{ availablePoints.toLocaleString() }}P</strong>
          <div class="checkbox">
            <input
              class="input-checkbox"
              type="checkbox"
              id="usePointAll"
              :checked="usePointAll"
              @change="toggleUsePointAll"
            />
            <label class="label" for="usePointAll">모두 사용</label>
          </div>
        </div>
      </li>
      <li class="item" :style="{ 'margin-top': '20px' }">
        <span class="title">사용 포인트</span>
        <div class="content">
          <input
            class="point-input"
            type="number"
            value="0"
            min="0"
            ref="pointInput"
            :max="limitAmount"
            @input="inputPoint"
            @blur="checkUpdateUsePoint"
            @focus="closeToast"
          />
        </div>
      </li>
    </ul>
    <p class="point-noti">{{ availablePointDesc }}</p>
  </div>
  <div class="radio" v-if="showCouponOption" @click="updateSelectedType(BENEFIT_TYPE.COUPON)">
    <input
      class="input-radio"
      type="radio"
      id="useCoupon"
      :value="BENEFIT_TYPE.COUPON"
      :checked="selected === BENEFIT_TYPE.COUPON"
    />
    <label class="label" for="useCoupon">
      <strong class="title">쿠폰</strong>
    </label>
    <label class="coupon-select" for="selectCoupon">
      <select class="select-box" id="selectCoupon" v-model="useCouponIdx">
        <option value="0">
          {{ availableCoupons.length > 0 ? '사용 안함' : '사용가능한 쿠폰이 없습니다.' }}
        </option>
        <option v-for="(coupon, idx) in availableCoupons" :key="idx" :value="coupon.dcsIdx">
          {{ coupon.label.title }}
        </option>
      </select>
    </label>
  </div>
  <transition name="toast">
    <p v-if="Boolean(toastMsg)" class="toast">{{ toastMsg }}</p>
  </transition>
</template>

<script lang="ts">
import stringTool from '@/common/stringTool';
import numberTool from '@/common/numberTool';
import {
  /* CONST */
  BENEFIT_TYPE,
  BENEFIT_OPTIONS,
  /* TYPE */
  TypeBenefitType,
} from '../modules/interface';
import { IOrderBenefit } from '../modules/OrderBenefit';

export default {
  name: 'AdditionalBenefit',
  emits: ['updateAdditionalDc'],
  props: {
    benefitInfo: {
      type: Object as () => IOrderBenefit,
      required: true,
    },
    useRepurchaseBenefit: {
      type: Boolean,
      required: true,
    },
    additionalDc: {
      type: Object as () => { type: TypeBenefitType; amount: number; couponIdx: number },
      required: true,
    },
  },
  data() {
    return {
      BENEFIT_TYPE,
      selected: null,
      availablePoints: 0,
      availableCoupons: [],
      usePoint: 0,
      usePointAll: false,
      useCouponIdx: '0',
      toastMsg: '',
      toastTimer: null,
    };
  },
  computed: {
    showPointOption() {
      return this.benefitInfo.option === BENEFIT_OPTIONS.BOTH || this.benefitInfo.option === BENEFIT_OPTIONS.POINT;
    },
    showCouponOption() {
      return this.benefitInfo.option === BENEFIT_OPTIONS.BOTH || this.benefitInfo.option === BENEFIT_OPTIONS.COUPON;
    },
    limitAmount() {
      return this.useRepurchaseBenefit ? this.benefitInfo.maxAmountRepurchase : this.benefitInfo.maxAmount;
    },
    limitPercent() {
      return this.useRepurchaseBenefit ? this.benefitInfo.maxPercentRepurchase : this.benefitInfo.maxPercent;
    },
    canUse() {
      return this.availablePoints > this.limitAmount ? this.limitAmount : this.availablePoints;
    },
    availablePointDesc() {
      return this.limitAmount > 0
        ? stringTool.replaceVars('{{1}}P 사용가능 (결제액의 {{2}}%, 최대 {{3}}P 한도)', [
            numberTool.format(this.canUse, null),
            numberTool.format(this.limitPercent, null),
            numberTool.format(this.limitAmount, null),
          ])
        : '포인트 사용이 불가능한 수강권입니다.';
    },
  },
  watch: {
    useRepurchaseBenefit() {
      if (this.selected !== BENEFIT_TYPE.POINT) return;
      if (!(this.usePointAll || this.canUse < this.usePoint)) return;
      this.updateUsePoint(this.canUse);
    },
    useCouponIdx() {
      this.updateUseCoupon(this.useCouponIdx);
    },
    selected() {
      if (this.selected === BENEFIT_TYPE.POINT) {
        this.updateUsePoint(this.usePoint);
      } else if (this.selected === BENEFIT_TYPE.COUPON) {
        this.updateUseCoupon(this.useCouponIdx);
        this.closeToast();
      }
    },
  },
  methods: {
    updateSelectedType(type: TypeBenefitType) {
      this.selected = type;
    },
    updateUseCoupon(couponIdx: number) {
      const selectedCoupon = this.availableCoupons?.find((coupon) => coupon.dcsIdx === couponIdx);
      const amount = selectedCoupon?.selectedPlanDiscountMoney || 0;
      const additionalDc = { type: BENEFIT_TYPE.COUPON, couponIdx, amount };
      this.$emit('update:additionalDc', additionalDc);
    },
    updateUsePoint(amount: number) {
      const additionalDc = { type: BENEFIT_TYPE.POINT, amount };
      this.$emit('update:additionalDc', additionalDc);
      this.usePoint = amount;
      this.$refs.pointInput.value = amount;
      this.toggleToast();
    },
    checkUpdateUsePoint(e) {
      const newUsePoint = Number(e.target.value);
      if (newUsePoint !== this.usePoint) {
        this.updateUsePoint(newUsePoint);
      }
    },
    toggleUsePointAll(e) {
      const usePointAll = e.target.checked;
      const amount = usePointAll ? this.canUse : 0;
      this.usePointAll = usePointAll;
      this.updateUsePoint(amount);
    },
    inputPoint(e) {
      let amount = Number(`${e.target.value}`.replace(/(^0+)/, ''));
      if (amount < this.canUse) {
        this.usePointAll = false;
      } else {
        this.usePointAll = true;
        amount = this.canUse;
      }
      e.target.value = `${amount}`;
    },
    toggleToast() {
      if (this.usePoint <= 0) {
        this.closeToast();
        return;
      }
      if (!this.toastMsg) {
        this.openToast();
        return;
      }
      this.closeToast();
      this.$nextTick(() => this.openToast());
    },
    openToast() {
      this.toastMsg = `${this.usePoint.toLocaleString()}P 사용되었습니다.`;
      this.toastTimer = setTimeout(this.closeToast.bind(this), 1500);
    },
    closeToast() {
      clearTimeout(this.toastTimer);
      this.toastMsg = '';
      this.toastTimer = null;
    },
  },
  async mounted() {
    if (this.benefitInfo.option === BENEFIT_OPTIONS.BOTH) {
      this.selected = BENEFIT_TYPE.POINT;
      this.availablePoints = await this.benefitInfo.getAvailablePoints();
      this.availableCoupons = await this.benefitInfo.getAvailableCoupons();
    } else if (this.benefitInfo.option === BENEFIT_OPTIONS.POINT) {
      this.selected = BENEFIT_TYPE.POINT;
      this.availablePoints = await this.benefitInfo.getAvailablePoints();
    } else if (this.benefitInfo.option === BENEFIT_OPTIONS.COUPON) {
      this.selected = BENEFIT_TYPE.COUPON;
      this.availableCoupons = await this.benefitInfo.getAvailableCoupons();
    }
  },
};
</script>

<style lang="scss" scoped>
.radio {
  position: relative;
  margin: 15px 13px 0;
}

.contents {
  margin: 0 27px;
  padding-top: 4.143rem;
  .checkbox {
    font-weight: 700;
    color: #919191;
    padding-left: 25px;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  .title {
    min-width: 85px;
    margin-right: 15px;
    color: #919191;
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    text-align: right;
    word-break: keep-all;
  }
  &--border {
    margin-top: 25px;
    padding-top: 18px;
    border-top: 2px solid #ededed;
  }
}

.point-input {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 46px;
  padding: 0 20px;
  border-radius: 8px;
  border: 2px solid #f2f2f2;
  color: #919191;
}

.point-noti {
  padding: 18px 25px 20px;
  font-size: 0.86rem;
  color: #777;
  line-height: 1.7;
}

.coupon-select {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 58px;
  padding: 4.143rem 2px 12px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-box {
  width: 88.95%;
  height: 40px;
  padding-left: 5px;
  background: url('//res.tutoring.co.kr/res/images/team/icon/ico_select.png') 95% 56% no-repeat;
  background-color: #ffffff;
  background-size: 12px 9px;
  -webkit-appearance: none;
  border: 2px solid #f2f2f2;
  border-radius: 6px;
  box-shadow: 0.13em 0.13em 1px 0 rgba(0, 0, 0, 0.05);
}

.input-radio {
  display: none;
  & + .label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #f2f2f2;
    border-radius: 5px;
    .title {
      display: flex;
      align-items: center;
      padding: 0 25px;
      height: 4.143rem;
      font-weight: 700;
      font-size: 1.145rem;
    }
    &::before {
      top: 12px;
      right: 22px;
      width: 28px;
      height: 28px;
      background-position: -170px 0;
    }
  }
  &:checked + .label {
    border-color: #34bcff;
    &::before {
      background-position: -170px -30px;
    }
  }
}

.input-checkbox {
  display: none;
  & + .label {
    position: relative;
    padding-left: 25px;
    &::before {
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background-position: -122px 0;
    }
  }
  &:checked + .label::before {
    background-position: -122px -30px;
  }
}

.label::before {
  content: '';
  position: absolute;
  background-image: url('//res.tutoring.co.kr/res/images/spr/ico_comm_181220.png');
  background-repeat: no-repeat;
  background-size: 200px 160px;
}

input,
select {
  outline: none;
}

.toast-enter-active,
.toast-leave-active {
  transition: opacity 0.5s ease;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
}

.toast {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 50%;
  width: 50%;
  height: auto;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  color: white;
  font-weight: 500;
  font-size: 17px;
  border-radius: 10px;
  box-shadow: rgba(92, 92, 92, 0.24) 0px 3px 8px;
  word-break: keep-all;
  background-color: #3b3b3b;
}

@media (max-width: 768px) {
  .toast {
    width: 90%;
  }
}
</style>
