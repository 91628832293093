
import {
  /* CONST */
  BENEFIT_TYPE,
  BENEFIT_OPTIONS,
  /* TYPE */
  TypePlan,
  TypeBenefitType,
  TypePayment,
} from '../modules/interface';
import { IOrder } from '../modules/Order';
import {
  RepurchaseBenefit,
  TopImage,
  UserInfoBox,
  AdditionalBenefit,
  OrderSheet,
  PayMethod,
  PlanOptions,
} from '../sections';
import PaymentKorean from '../../payment/modules/PaymentKorean';

export default {
  name: 'StandardPlan',
  components: {
    RepurchaseBenefit,
    TopImage,
    AdditionalBenefit,
    OrderSheet,
    UserInfoBox,
    PayMethod,
    PlanOptions,
  },
  emits: ['changePlan', 'showAlert', 'setIsLoading'],
  props: {
    orderInfo: {
      type: Object as () => IOrder,
      required: true,
    },
    planOptions: {
      type: Object as () => Array<TypePlan>,
      required: true,
    },
  },
  data() {
    return {
      useRepurchaseBenefit: true as boolean,
      additionalDc: {
        type: BENEFIT_TYPE.POINT,
        amount: 0,
      } as {
        type: TypeBenefitType;
        amount: number;
        couponIdx?: number;
      },
    };
  },
  computed: {
    isShowAdditionalBenefit() {
      return this.orderInfo.benefit.option !== BENEFIT_OPTIONS.NONE;
    },
  },
  methods: {
    changePlan(plan: TypePlan) {
      this.$emit('changePlan', plan);
    },
    async goToPay(finalPrice: number) {
      console.log();
      if (!this.orderInfo.pay.checkAgree()) {
        this.$emit('showAlert', { msg: '결제 동의를 확인해주세요' });
        return;
      }
      this.$emit('setIsLoading', true);
      const { success, data } = await this.orderInfo.submitPay(finalPrice, this.additionalDc);
      if (!success) {
        const { title, msg } = data as { title: string; msg: string };
        this.$emit('setIsLoading', false);
        this.$emit('showAlert', { title, msg });
        return;
      }
      const mtIdx = data as string;
      this.openPaymentGateway(finalPrice, mtIdx);
      this.$emit('setIsLoading', false);
    },
    async openPaymentGateway(finalPrice: number, mtIdx: string) {
      const {
        code,
        nameForPG: titleForPG,
        period: { months },
      } = this.orderInfo.item;
      const { method: payMethod } = this.orderInfo.pay;
      const paymentInfo = {
        mtIdx,
        code,
        titleForPG,
        payMethod,
        totalPaidMoney: finalPrice,
        months,
      } as TypePayment;
      const payment = new PaymentKorean(paymentInfo, false);
      await payment.init();
      await payment.loadPgForm();
      await payment.fillPgForm();
      payment.submitPgForm();
    },
  },
};
