<template>
  <div v-if="isAlphaPlan" class="wrapper">
    <p class="title">수강권 선택</p>
    <div v-for="(group, i) in optionGroups" :key="i" class="group">
      <p class="title">{{ i + 1 }}) 단과 패스 {{ group[0].monthCnt }}개월권 - {{ group[0].minute }}분 수업</p>
      <ul>
        <li v-for="(opt, j) in group" :key="j" class="item">
          <input type="radio" name="plan" :id="`option-${opt.cpIdx}`" :value="opt.cpIdx" v-model="selected" />
          <label class="label" :for="`option-${opt.cpIdx}`">
            <span class="lesson">
              <i class="summary">{{ opt.monthCnt }}개월 {{ opt.tutor }} (주 {{ opt.lessonPerWeek }}회)</i>
              <i class="detail">
                1:1 영어회화 {{ opt.minute }}분 X 주 {{ opt.lessonPerWeek }}회, 총 {{ opt.lessonCnt }}회 제공
              </i>
            </span>
            <span :class="['money', { 'is-best': opt.isBest }]">
              <i class="total">
                {{ opt.moneyPerMonth.toLocaleString() }}원
                <em>/월</em>
              </i>
              <i class="origin">월 {{ opt.originMoneyPerMonth.toLocaleString() }}원</i>
            </span>
          </label>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="isEngTechPlan" class="wrapper">
    <div v-for="(group, i) in optionGroups" :key="i" class="group">
      <p class="title">{{ i + 1 }}) {{ group[0].tutor }} {{ group[0].minute }}분 수업</p>
      <ul>
        <li v-for="(opt, j) in group" :key="j" class="item">
          <input type="radio" name="plan" :id="`option-${opt.cpIdx}`" :value="opt.cpIdx" v-model="selected" />
          <label class="label" :for="`option-${opt.cpIdx}`">
            <span class="lesson">
              <i class="summary">{{ opt.monthCnt }}개월 {{ opt.minute }}분 {{ opt.lessonCnt }}회</i>
              <i class="detail">
                {{ opt.product?.map((prd) => `+ ${prd} `).join('') }}
              </i>
            </span>
            <span :class="['money', { 'is-best': opt.isBest }]">
              <i class="total">
                {{ opt.moneyPerMonth.toLocaleString() }}원
                <em>/월</em>
              </i>
              <i class="origin">월 {{ opt.originMoneyPerMonth.toLocaleString() }}원</i>
            </span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {
  /* CONST */
  PLAN_CODE,
  TUTOR_LABEL,
  TUTOR_TYPE,
  /* TYPE */
  TypePlan,
  TypeTutorLabel,
} from '../modules/interface';
import { IOrderItem } from '../modules/OrderItem';

type TypeParsedOption = {
  cpIdx: number;
  tutor: TypeTutorLabel;
  monthCnt: number;
  lessonPerWeek: number;
  minute: number;
  lessonCnt: number;
  moneyPerMonth: number;
  originMoneyPerMonth: number;
  isBest: boolean;
  product?: Array<string>;
};

export default {
  name: 'PlanOptions',
  emits: ['changePlan'],
  props: {
    itemInfo: {
      type: Object as () => IOrderItem,
      required: true,
    },
    planOptions: {
      type: Object as () => Array<TypePlan>,
      required: true,
    },
  },
  computed: {
    isAlphaPlan() {
      return this.itemInfo.code === PLAN_CODE.ALPHA_PLAN;
    },
    isEngTechPlan() {
      return this.itemInfo.code === PLAN_CODE.JICDING_ENGLISHTECH;
    },
  },
  data() {
    return {
      optionGroups: [] as Array<Array<TypeParsedOption>>,
      selected: this.itemInfo.cpIdx as Number,
    };
  },
  watch: {
    selected() {
      const plan = this.planOptions.find((opt) => opt.cp_idx === this.selected);
      if (plan) {
        this.$emit('changePlan', plan);
      }
    },
  },
  created() {
    if (this.itemInfo.code === PLAN_CODE.ALPHA_PLAN) {
      this.optionGroups = this.planOptions.reduce(
        (optList, option) => {
          const {
            cp_idx: cpIdx,
            month_cnt: monthCnt,
            lessonPerWeek,
            minute,
            t_type: tutorType,
            lesson_cnt: lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
          } = option;
          const type = `${monthCnt}${minute}`;
          const tutor = TUTOR_LABEL[tutorType];
          const isBest = type === '620' && tutorType === TUTOR_TYPE.GLOBAL;
          let idx = -1;
          if (type === '610') idx = 0;
          else if (type === '620') idx = 1;
          else if (type === '310') idx = 2;
          else if (type === '320') idx = 3;
          optList[idx].push({
            cpIdx,
            tutor,
            isBest,
            monthCnt,
            lessonPerWeek,
            minute,
            lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
          });
          return optList;
        },
        [[], [], [], []] as Array<Array<TypeParsedOption>>,
      );
    } else if (this.itemInfo.code === PLAN_CODE.JICDING_ENGLISHTECH) {
      this.optionGroups = this.planOptions.reduce(
        (optList, option) => {
          const {
            cp_idx: cpIdx,
            month_cnt: monthCnt,
            lessonPerWeek,
            minute,
            t_type: tutorType,
            lesson_cnt: lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
            options,
          } = option;
          const type = `${tutorType}${minute}`;
          const tutor = TUTOR_LABEL[tutorType];
          const isBest = false;
          const product = options.map((opt) => opt.title);
          let idx = -1;
          if (type === 'C10') idx = 0;
          else if (type === 'B10') idx = 1;
          else if (type === 'C20') idx = 2;
          else if (type === 'B20') idx = 3;
          optList[idx].push({
            cpIdx,
            tutor,
            isBest,
            monthCnt,
            lessonPerWeek,
            minute,
            lessonCnt,
            moneyPerMonth,
            originMoneyPerMonth,
            product,
          });
          return optList;
        },
        [[], [], [], []] as Array<Array<TypeParsedOption>>,
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 22px 10px 12px;
}

.title {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 1.145rem;
  line-height: 1.3;
}

.group {
  & + .group {
    border-top: 1px solid #c4c4c4;
  }
  .title {
    padding: 20px 0 18px;
  }
  .item {
    input[type='radio'] {
      display: none;
      & + .label::before {
        content: '';
        position: absolute;
        top: 17px;
        left: 15px;
        width: 28px;
        height: 28px;
        background-image: url('//res.tutoring.co.kr/res/images/spr/ico_comm_181220.png');
        background-repeat: no-repeat;
        background-size: 200px 160px;
        background-position: -170px 0;
      }
      &:checked + .label {
        .lesson {
          color: #00abff;
        }
        &::before {
          background-position: -170px -30px;
        }
      }
    }
  }
  .label {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 10px 55px;
    box-sizing: content-box;
  }
  .lesson,
  .money {
    display: flex;
    flex-direction: column;
    i {
      font-style: normal;
    }
  }
  .summary {
    font-weight: 700;
    font-size: 1.145rem;
  }
  .detail {
    font-size: 0.786rem;
    line-height: 2.5;
  }
  .money {
    align-items: center;
    justify-content: center;
  }
  .total {
    font-weight: 700;
    em {
      color: #919191;
      font-size: 0.7142rem;
      font-weight: normal;
      font-style: normal;
    }
  }
  .origin {
    position: relative;
    width: fit-content;
    font-size: 0.5714rem;
    color: #d0d0d0;
    &::before {
      content: '';
      position: absolute;
      top: -3px;
      right: 5px;
      width: 1px;
      height: 15px;
      background-color: #d0d0d0;
      transform: rotate(-55deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #d0d0d0;
    }
  }
  .is-best {
    position: relative;
    &::before {
      content: 'Best';
      position: absolute;
      display: flex;
      top: 5px;
      left: -25px;
      align-items: center;
      justify-content: center;
      width: 21.52px;
      height: 21.52px;
      background: #333;
      border-radius: 50%;
      transform: rotate(-15deg);
      font-size: 7.87001px;
      line-height: 9px;
      letter-spacing: -0.3935px;
      color: #fff;
    }
  }
}
</style>
