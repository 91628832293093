<template>
  <div class="delivery-form">
    <strong class="title">배송지 정보 (* 표시는 필수 입력 사항)</strong>
    <p class="line">
      <input class="input" type="text" placeholder="받는 사람 *" v-model="recipient" @blur="updateDeliveryInfo" />
    </p>
    <p class="line" v-if="isShowMobile">
      <input class="input" type="tel" placeholder="핸드폰 번호 *" v-model="mobile" :disabled="phoneAuth" />
      <button class="certification-btn" type="button" @click="requestPhoneCode" :disabled="phoneAuth">인증</button>
    </p>
    <p class="line" v-if="phoneCodeRemainTime">
      <input class="input" type="text" placeholder="인증 번호 4자리 입력 *" v-model="phoneCode" />
      <span class="count-time">{{ phoneCodeRemainTime }}</span>
      <button class="certification-btn" @click="requestConfirmPhone">확인</button>
    </p>
    <p class="line" v-if="isShowTel">
      <input class="input" type="tel" placeholder="집전화" v-model="tel" @blur="updateDeliveryInfo" />
    </p>
    <div v-if="isShowAddress">
      <p class="line zipcode">
        <input class="input" type="text" placeholder="우편번호 *" disabled v-model="zipCode" />
        <button class="zipcode-btn" @click="isPostSearchShow = true">우편번호 검색</button>
      </p>
      <p class="line">
        <input class="input" type="text" placeholder="주소 *" v-model="address1" @blur="updateDeliveryInfo" />
      </p>
      <p class="line">
        <input class="input" type="text" placeholder="상세 주소" v-model="address2" @blur="updateDeliveryInfo" />
      </p>
      <p class="line">
        <input class="input" type="text" placeholder="배송메세지" v-model="message" @blur="updateDeliveryInfo" />
      </p>
    </div>
  </div>
  <!-- 우편번호 검색 -->
  <PostCodeSearch v-if="isPostSearchShow" @complete="setAddress" @close="isPostSearchShow = false" />
  <!-- Alert 팝업 -->
  <Alert :title="alertTitle" :msg="alertMsg" @close="closeAlert" />
</template>

<script lang="ts">
import date from '@/common/date';
import { DELIVERY_TYPE } from '../modules/interface';
import { IOrderDelivery } from '../modules/OrderDelivery';
import { PostCodeSearch, Alert } from '../components';

export default {
  name: 'DeliveryForm',
  components: {
    PostCodeSearch,
    Alert,
  },
  emits: ['updateDeliveryInfo'],
  props: {
    deliveryInfo: {
      type: Object as () => IOrderDelivery,
      required: true,
    },
  },
  data() {
    return {
      recipient: '',
      mobile: '',
      tel: '',
      zipCode: '',
      address1: '',
      address2: '',
      message: '',
      phoneCode: '',
      phoneAuth: false,
      phoneCodeRemainTime: '',
      phoneAuthTimer: null,
      alertTitle: '',
      alertMsg: '',
      isPostSearchShow: false,
    };
  },
  computed: {
    isShowMobile() {
      return this.deliveryInfo.type === DELIVERY_TYPE.S || this.deliveryInfo.type === DELIVERY_TYPE.SP;
    },
    isShowTel() {
      return this.deliveryInfo.type === DELIVERY_TYPE.S;
    },
    isShowAddress() {
      return this.deliveryInfo.type === DELIVERY_TYPE.S || this.deliveryInfo.type === DELIVERY_TYPE.SD;
    },
  },
  watch: {
    deliveryInfo() {
      this.updateDeliveryInfo();
    },
  },
  methods: {
    updateDeliveryInfo() {
      // eslint-disable-next-line vue/no-mutating-props
      if (this.recipient) this.deliveryInfo.recipient = this.recipient;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.phoneAuth && this.mobile) this.deliveryInfo.mobile = this.mobile;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.tel) this.deliveryInfo.tel = this.tel;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.zipCode) this.deliveryInfo.zipCode = this.zipCode;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.address1) this.deliveryInfo.address1 = this.address1;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.address2) this.deliveryInfo.address2 = this.address2;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.recipient) this.deliveryInfo.recipient = this.recipient;
      // eslint-disable-next-line vue/no-mutating-props
      if (this.message) this.deliveryInfo.message = this.message;
    },
    setPhoneAuthTimer(expireDt: string) {
      const expireTime = date.stringToDate(expireDt);
      const now = new Date().getTime();
      let diff = expireTime.valueOf() - now.valueOf();

      const phoneAuthFunc = () => {
        diff -= 1000;
        const secs = diff <= 0 ? 0 : diff / 1000;
        const min = Math.floor((secs / 60) % 60);
        this.phoneCodeRemainTime = min > 0 ? `${min}분 ${Math.floor(secs % 60)}초` : `${Math.floor(secs % 60)}초`;
        if (diff <= 0) {
          this.clearPhoneAuthTimer();
        }
      };
      phoneAuthFunc();
      this.phoneAuthTimer = setInterval(phoneAuthFunc, 1000);
    },
    clearPhoneAuthTimer() {
      if (this.phoneAuthTimer) {
        clearInterval(this.phoneAuthTimer);
        this.phoneCodeRemainTime = '';
      }
    },
    async requestPhoneCode() {
      if (this.mobile) {
        const { success, data } = await this.deliveryInfo.requestMobileCheck(this.mobile);
        this.clearPhoneAuthTimer();
        if (success) {
          this.alertMsg = '휴대폰으로 발송된 인증번호 4자리를 입력해주세요.';
          this.phoneCode = '';
          this.setPhoneAuthTimer(data);
        } else {
          this.alertTitle = '인증 번호 발송 실패';
          this.alertMsg = '인증 번호 발송이 실패했습니다.\n휴대폰 번호를 다시 한번 확인해주세요.';
        }
      } else {
        this.alertMsg = '배송받는 분 연락처를 입력해주세요';
      }
    },
    async requestConfirmPhone() {
      const { success, data: msg } = await this.deliveryInfo.confirmMobileCheck(this.mobile, this.phoneCode);
      this.alertMsg = msg;
      if (success) {
        this.clearPhoneAuthTimer();
        this.updateDeliveryInfo();
        this.phoneAuth = true;
      }
    },
    setAddress(data: { zipCodeData: string; addressData: string }) {
      const { zipCodeData: zipCode, addressData: address1 } = data;
      this.zipCode = zipCode;
      this.address1 = address1;
      this.isPostSearchShow = false;
      this.updateDeliveryInfo();
    },
    closeAlert() {
      this.alertTitle = '';
      this.alertMsg = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-form {
  margin-top: -18px;
  padding: 0 20px 37px;
  background-color: #f9f9f9;
  .title {
    display: block;
    padding: 0 10px 10px 10px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
  }
  .line {
    margin-top: 4px;
    position: relative;
  }
  .input {
    display: block;
    width: 100%;
    height: 46px;
    padding: 1px 13px 0;
    border-radius: 5px;
    border: 2px solid #f2f2f2;
    appearance: none;
    box-sizing: border-box;
    &::placeholder {
      word-break: keep-all;
      font-size: 0.88rem;
      line-height: 1.3;
      color: #999;
      opacity: 0.5;
    }
  }
  .count-time {
    position: absolute;
    top: 15px;
    right: 80px;
    font-size: 0.86rem;
    color: #ff5959;
  }
  .certification-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 60px;
    height: 32px;
    margin-top: -16px;
    border-radius: 32px;
    border: solid 1.5px rgba(151, 151, 151, 0.2);
    background-color: transparent;
    color: #777777;
  }
  .zipcode {
    margin-top: 32px;
    display: flex;
    align-items: center;
  }
  .zipcode-btn {
    min-width: 110px;
    height: 44px;
    margin-left: 7px;
    border-radius: 5px;
    background-color: #ececec;
  }
}

input {
  outline: none;
}
</style>
