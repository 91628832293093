<template>
  <ul class="plan-info" v-if="showPlanInfo">
    <li class="item">
      <span class="title">상품명</span>
      <strong class="content">{{ itemInfo.name }}</strong>
    </li>
    <li class="item">
      <span class="title">수강권</span>
      <strong class="content">{{ lessonInfo }}</strong>
    </li>
    <li class="item">
      <span class="title">수강기간</span>
      <strong class="content">{{ itemInfo.period.frDt }} ~ {{ itemInfo.period.toDt }}</strong>
    </li>
  </ul>
  <ul class="payment-info">
    <li class="item">
      <span class="title">할인 금액</span>
      <span class="content discount">
        <i>{{ discountPercent }}</i>
        -{{ discountMoney }}
      </span>
    </li>
    <li class="item">
      <span class="title">추가 할인</span>
      <span class="content benefit">{{ addtionalBenefit }}</span>
    </li>
    <li class="benefit-noti" v-if="!showPlanInfo && payInfo.earningPoints > 0">
      {{ bonusPointDesc }}
    </li>
    <li class="item item--border">
      <span class="title">총 결제 금액</span>
      <span class="content total">
        <i class="monthly">{{ monthlyPaidMoney }}</i>
        <strong class="amount">{{ totalPaidMoney }}</strong>
      </span>
    </li>
    <li class="agree" v-if="!showPlanInfo">
      <div class="checkbox">
        <input type="checkbox" id="agree" v-model="isAgree" />
        <label class="label" for="agree">
          주문 내역 및 결제 정보를 확인하였으며, 개인정보수집 및
          <i @click="showTerm">이용약관</i>
          에 동의합니다.
        </label>
      </div>
      <button class="btn" @click="goToPay">결제 수단 확인하기</button>
    </li>
  </ul>
</template>

<script lang="ts">
import stringTool from '@/common/stringTool';
import numberTool from '@/common/numberTool';
import {
  /* CONST */
  BENEFIT_TYPE,
  PLAN_CODE,
  /* TYPE */
  TypeBenefitType,
} from '../modules/interface';
import { IOrderItem } from '../modules/OrderItem';
import { IOrderPay } from '../modules/OrderPay';

export default {
  name: 'OrderSheet',
  emits: ['goToPay'],
  props: {
    itemInfo: {
      type: Object as () => IOrderItem,
      required: true,
    },
    payInfo: {
      type: Object as () => IOrderPay,
      required: true,
    },
    additionalDc: {
      type: Object as () => { type: TypeBenefitType; amount: number },
      required: false,
    },
    showPlanInfo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isAgree: false as boolean,
    };
  },
  computed: {
    finalPrice() {
      return this.payInfo.salePrice - (this.additionalDc?.amount || 0);
    },
    lessonInfo() {
      let lessonInfo = '';
      if (this.itemInfo.period.months <= 0) {
        lessonInfo = stringTool.replaceVars('{{1}}일  | 총 {{2}}회', [
          this.itemInfo.period.days,
          this.itemInfo.lessonCnt,
        ]);
      } else if (this.itemInfo.period.months >= 3 && this.itemInfo.code === PLAN_CODE.BASIC) {
        lessonInfo = stringTool.replaceVars('주 {{1}}회 | 월 {{2}}회 X {{3}}개월 | 총 {{4}}회', [
          this.itemInfo.lessonCntPerWeek,
          this.itemInfo.lessonCnt / this.itemInfo.period.months,
          this.itemInfo.period.months,
          this.itemInfo.lessonCnt,
        ]);
      } else {
        lessonInfo = stringTool.replaceVars('주 {{1}}회 (월 {{4}}회) | {{2}}개월 | 총 {{3}}회', [
          this.itemInfo.lessonCntPerWeek,
          this.itemInfo.period.months,
          this.itemInfo.lessonCnt,
          this.itemInfo.lessonCnt / this.itemInfo.period.months,
        ]);
      }
      return lessonInfo;
    },
    discountPercent() {
      return this.showPlanInfo ? `${this.payInfo.discountedPercent}% 할인` : `-${this.payInfo.discountedPercent}%`;
    },
    discountMoney() {
      return stringTool.replaceVars('{{1}}원', [numberTool.format(Math.floor(this.payInfo.discountedAmount), null)]);
    },
    addtionalBenefit() {
      const amount = (this.additionalDc?.amount || 0).toLocaleString();
      if (this.additionalDc?.type === BENEFIT_TYPE.COUPON) {
        return stringTool.replaceVars('{{1}}원', [amount]);
      }
      return `${amount}P`;
    },
    bonusPointDesc() {
      return stringTool.replaceVars('구매액의 {{1}}%가 포인트로 적립됩니다.', [this.payInfo.earningPoints]);
    },
    monthlyPaidMoney() {
      return stringTool.replaceVars('월 {{1}}원 X {{2}}개월', [
        numberTool.format(this.payInfo.payPerMonth, null),
        this.itemInfo.period.months,
      ]);
    },
    totalPaidMoney() {
      return stringTool.replaceVars('총 {{1}}원', [numberTool.format(this.finalPrice, null)]);
    },
  },
  watch: {
    isAgree() {
      this.updateAgree();
    },
    payInfo() {
      if (this.isAgree !== this.payInfo.agree) {
        this.updateAgree();
      }
    },
  },
  methods: {
    updateAgree() {
      console.log('updateAgree!!!');
      // eslint-disable-next-line vue/no-mutating-props
      this.payInfo.agree = this.isAgree;
    },
    goToPay() {
      this.$emit('goToPay', this.finalPrice);
    },
    showTerm() {
      window.open('https://tutoring.co.kr/home/help/Z', '', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-info {
  margin: 10px 20px 27px;
  .item {
    margin-top: 20px;
  }
}

.payment-info {
  margin: 10px 13px 0;
  padding: 15px 17px 19px;
  border-radius: 8px;
  background-color: #f9f9f9;
  .title {
    font-weight: 700;
  }
}

.benefit,
.discount {
  color: #ff5959;
}

.discount i {
  font-style: normal;
  color: #919191;
  margin-right: 6px;
}

.total {
  font-weight: 700;
  .monthly {
    display: block;
    font-style: normal;
    color: #727272;
  }
  .amount {
    display: block;
    font-size: 1.8rem;
    color: #0099ff;
  }
}

.benefit-noti {
  margin-top: 9px;
  font-size: 0.86rem;
  line-height: 1.5;
}

.agree {
  margin-top: 43px;
  .checkbox {
    position: relative;
    padding-left: 32px;
  }
  .label {
    font-weight: 700;
    font-size: 1.145rem;
    word-break: keep-all;
    &::before {
      top: 1px;
      left: 0;
      width: 23px;
      height: 23px;
      background-position: -68px 0;
    }
    i {
      text-decoration: underline;
      font-style: normal;
      cursor: pointer;
    }
  }
  input[type='checkbox'] {
    display: none;
    &:checked + .label::before {
      background-position: -68px -28px;
    }
  }
  .btn {
    display: block;
    width: 100%;
    height: 60px;
    margin-top: 32px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 8px;
    border: 0 none;
    background-color: #34bcff;
    color: #fff;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .title {
    min-width: 100px;
    color: #919191;
  }
  .content {
    flex: 1;
    text-align: right;
    word-break: keep-all;
  }
  &--border {
    margin-top: 25px;
    padding-top: 18px;
    border-top: 2px solid #ededed;
  }
}

.label::before {
  content: '';
  position: absolute;
  background-image: url('//res.tutoring.co.kr/res/images/spr/ico_comm_181220.png');
  background-repeat: no-repeat;
  background-size: 200px 160px;
}
</style>
